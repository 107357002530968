<fieldset class="section-legend-border col-md-12 col-lg-12" #ManageLocation >
    <legend class="section-legend-border">Manage Sub Location</legend>
<p>Works with only sublocation for ex. Almirahs, computertable etc</p>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #SubLocation >
                <legend class="section-legend-border">Sub Location</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.LocationName}} | {{r.LocationUniqueName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sub Location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="locationModel.ItemTrackerLocationAutoComplete" [(ngModel)]="locationModel.ItemTrackerLocationAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <label for="disabledTextInput">Location ID</label>
                    <input type="text" [(ngModel)]="locationModel.LocationID" name="locationModel.LocationID" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <label for="disabledTextInput">Location Name</label>
                    <input type="text" [(ngModel)]="locationModel.LocationName" name="locationModel.LocationName" placeholder="Location Name " class="form-control">
                </div>
            </div>
            <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #MainLocation >
                <legend class="section-legend-border">Main Location For Sub Location</legend>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <ng-template #rtMainLocation let-r="result" let-t="term">
                        {{ r.LocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Main Location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="locationModel.ItemTrackerMainLocationAutoComplete" [(ngModel)]="locationModel.ItemTrackerMainLocationAutoComplete" [ngbTypeahead]="searchMainLocation" [inputFormatter]="formatterMainLocation" [resultTemplate]="rtMainLocation" (selectItem)="selectedItemMainLocation($event)"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <label for="disabledTextInput">Location Unique Name</label>
                    <input type="text" [(ngModel)]="locationModel.LocationUniqueName" name="locationModel.LocationUniqueName" placeholder="id" class="form-control">
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <label for="disabledTextInput">Main Location ID</label>
                    <input type="text" [(ngModel)]="locationModel.MainLocationID" name="locationModel.MainLocationID" placeholder="Main Location ID" class="form-control">
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <label for="disabledTextInput">Main Location Name</label>
                    <input type="text" [(ngModel)]="locationModel.MainLocationName" name="locationModel.MainLocationName" placeholder="Main Location ID" class="form-control" disabled>
                </div>
            </div>
            </fieldset>
            </fieldset>
     
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
                <div class="form-group col-sm-12 col-md-4 col-lg-3">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #SubLocationList >
    <legend class="section-legend-border">Sub Location List</legend>
<div class="row" id="Item-location-List">
    <div class="col-sm-12 example-section">
      <div class="">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">LocationID</th>
              <th scope="col">LocationName</th>
              <th scope="col">LocationUniqueName</th>
              <th scope="col">MainLocationID</th> 
              <th scope="col">MainLocation Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemTrackerLocationList">
              <th scope="row">{{item.LocationID}}</th>
              <td>{{item.LocationName}}</td>
              <td>{{item.LocationUniqueName}}</td>
              <td>{{item.MainLocationID}}</td>
              <td>{{item.MainLocationName}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
</fieldset>