import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrandComponent } from './brand/brand.component';
import { FoodComponent } from './food/food.component';
import { FoodLogComponent } from './food-log/food-log.component';
import { MealTypeComponent } from './meal-type/meal-type.component';
import { MeasurementComponent } from './measurement/measurement.component';
import { NutritionFactComponent } from './nutrition-fact/nutrition-fact.component';
import { TagComponent } from './tag/tag.component';
import { FoodImageComponent } from './food-image/food-image.component';
import { AFoodLoggerMasterComponent } from './afood-logger-master/afood-logger-master.component';
import { FitBitModule } from '../fit-bit/fit-bit.module';
import { FitBitWaterComponent } from '../fit-bit/fit-bit-water/fit-bit-water.component';
import { FoodByNutrutionComponent } from './food-by-nutrution/food-by-nutrution.component';
import { BulkFoodLoggerComponent } from './bulk-food-logger/bulk-food-logger.component';
import { SharedModule } from 'src/app/shared-module/shared.module';

@NgModule({
  declarations: [BrandComponent, FoodComponent, FoodLogComponent, MealTypeComponent, MeasurementComponent
    , NutritionFactComponent, TagComponent, FoodImageComponent, AFoodLoggerMasterComponent, FoodByNutrutionComponent, BulkFoodLoggerComponent
  
  ],
  imports: [
    CommonModule,AgGridModule,NgbModule,RouterModule,FormsModule,FitBitModule,SharedModule
  ]
})
export class FoodLoggerModule { }
