import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransactionMasterModel } from 'src/app/models/ExpenseManager/Transaction/transaction-master-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';

@Component({
  selector: 'app-voucher-details-popup',
  templateUrl: './voucher-details-popup.component.html',
  styleUrls: ['./voucher-details-popup.component.css']
})
export class VoucherDetailsPopupComponent implements OnInit {
  public popupHeading = '';
  public voucherID: number = 0;
  public transactionMasterModel: TransactionMasterModel;
  public crTotal = 0; drTotal = 0;
  constructor(public activeModal: NgbActiveModal, private expenseManagerService: ExpenseManagerService) { }
  ngOnInit(): void {
    this.expenseManagerService.GetVoucherDetailsById(this.voucherID).subscribe(voucherDetailsResponse => {
      this.transactionMasterModel = voucherDetailsResponse;
      this.crTotal = this.transactionMasterModel.ExpenseTTransModel.filter(item => item.TranType === 'C')
        .reduce((sum, current) => sum + current.Amount, 0);
      this.drTotal = this.transactionMasterModel.ExpenseTTransModel.filter(item => item.TranType === 'D')
        .reduce((sum, current) => sum + current.Amount, 0);
    });
  }
}
