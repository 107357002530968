export class ShoppingBasketModel {
   public ProductID:number;
   public ProductName:string;
   public VendorID:number;
   public VendorName:string;
   public Amount:number;
   public Quantity:number;
   public MeasurementType:string;
   public MeasurementId:number;
public AccountCode:string;
    public IsCheckedForShopping:boolean;
}
export class ShoppingBasketTransactionModel {
    ProductID:number;
    ProductName:string;
    VendorID:number;
    VendorName:string;
    Amount:number;
    Qty:number;
    QtyType:string;
}
