import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterLinkInfoService {

  constructor() { }

  getPageName(pageLink:string){
   if(pageLink.indexOf('managevendor')!== -1){
     return 'Vendor';
   }
   else if(pageLink.indexOf('login')!== -1){
    return 'Login';
  }
  else if(pageLink.indexOf('expense/productaccountdetails')!== -1){
    return 'Product/Account Details';
  }
   else if(pageLink.indexOf('product')!== -1){
    return 'Product';
  }
  else if(pageLink.indexOf('account')!== -1){
    return 'Account';
  }
  else if(pageLink.indexOf('transaction')!== -1){
    return 'Transaction';
  }
  else if(pageLink.indexOf('expense/file')!== -1){
    return 'File';
  }  
  else if(pageLink.indexOf('expense/shopping')!== -1){
    return 'Shopping Basket';
  }  
  else if(pageLink.indexOf('openingbalance')!== -1){
    return 'Opening Balance';
  }
  else if(pageLink.indexOf('productdetails')!== -1){
    return 'Product Details';
  }
  else if(pageLink.indexOf('category')!== -1){
    return 'Habit Category';
  }
  else if(pageLink.indexOf('habit/type')!== -1){
    return 'Habit Type';
  }
  else if(pageLink.indexOf('habit/habit')!== -1){
    return 'Habit';
  }
  else if(pageLink.indexOf('habit/mapping')!== -1){
    return 'Habit Mapping';
  }
  else if(pageLink.indexOf('habit/log')!== -1){
    return 'Habit Log';
  }
  else if(pageLink.indexOf('habit/report')!== -1){
    return 'Habit Report';
  }
  else if(pageLink.indexOf('home')!== -1){
    return 'Home';
  } 
  else if(pageLink.indexOf('dashboard')!== -1){
    return 'DashBoard';
  }
  else if(pageLink.indexOf('testtable')!== -1){
    return 'TestTable';
  }
  else if(pageLink.indexOf('highchart')!== -1){
    return 'High Chart';
  }
  else if(pageLink.indexOf('sensor/roomsensor')!== -1){
    return 'Room Sensor';
  }
  else if(pageLink.indexOf('sensordata/managesensormapping')!== -1){
    return 'Manage Sensor Mapping';
  }
  else if(pageLink.indexOf('sensordata/managesensor')!== -1){
    return 'Manage Sensor';
  }
  else if(pageLink.indexOf('sensordata/managefeatureType')!== -1){
    return 'Manage Sensor Feature Type';
  }
  else if(pageLink.indexOf('sensordata/manageLocation')!== -1){
    return 'Manage Sensor Location';
  }
  else if(pageLink.indexOf('sensordata/managesublocation')!== -1){
    return 'Manage Sensor Sub Location';
  } 
  else if(pageLink.indexOf('sensordata/watertank')!== -1){
    return 'Water Tank';
  }
  else if(pageLink.indexOf('foodlogger/managebrand')!== -1){
    return 'Manage Brand';
  }
  else if(pageLink.indexOf('foodlogger/managemealtype')!== -1){
    return 'Meal Type';
  }
  else if(pageLink.indexOf('foodlogger/createfood')!== -1){
    return 'Create Food';
  }
    else if(pageLink.indexOf('foodlogger/logfood')!== -1){
    return 'Log Food';
  }else if(pageLink.indexOf('foodlogger/foodbynutrition')!== -1){
    return 'Food By Nutrition';
  }
  else if(pageLink.indexOf('interview/managequestionanswer')!== -1){
    return 'Manage Question Answer';
  }
  else if(pageLink.indexOf('interview/managetopic')!== -1){
    return 'Manage Topic';
  }
  else if(pageLink.indexOf('interview/managesubject')!== -1){
    return 'Manage Subjects';
  } 
  else if(pageLink.indexOf('interview/questionbank')!== -1){
    return 'Question Bank';
  }
  else if(pageLink.indexOf('vitalinfo/measurement')!== -1){
    return 'Vital Information Measurement';
  } 
  else if(pageLink.indexOf('itemtracker/managelocation')!== -1){
    return 'Manage Location';
  }
  else if(pageLink.indexOf('itemtracker/managecurrentlocationdrg')!== -1){
    return 'Drag Drop Manage Item Current Location ';
  }
  else if(pageLink.indexOf('itemtracker/managecurrentlocation')!== -1){
    return 'Manage Item Current Location';
  }    
  else if(pageLink.indexOf('itemtracker/stockmapping')!== -1){
    return 'Stock Mapping';
  }  
  else if(pageLink.indexOf('smartbag/mastercombo')!== -1){
    return 'Manage Combo Master';
  }  
else if(pageLink.indexOf('itemtracker/itemlistbylocation')!== -1){
    return 'Item List By Current Location';
  }  
   else{
     return '';
   }
  }
}
