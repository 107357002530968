import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modalex',
  templateUrl: './modalex.component.html',
  styleUrls: ['./modalex.component.css']
})
export class ModalexComponent implements OnInit {
public outsideinput:string;
//https://stackblitz.com/edit/angular-ngbmodal?file=app%2Fmy-modal%2Fmy-modal.component.ts,app%2Fmy-modal%2Fmy-modal.component.html
constructor(public activeModal: NgbActiveModal) { 


}
ngOnInit() {

}
}
