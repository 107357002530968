import { FoodModel } from '../Food/food-model';
import { MealTypeModel } from '../MealType/meal-type-model';

export class FoodLogModel {
    public  FoodLogId:number; 
    public FoodModel:FoodModel; 
    public  Quantity:number; 
    public  ServingQuantity :number;
    public MealTypeModel:MealTypeModel ;
    public  Created:Date; 
    public  DisplayCreated:string; 
    public DateDisplay:any;
    public TimeDisplay:any;
    public CalculatedPrice:number;
    public UserID:number;
    public RecipeID:number;  
    public RecipeName:string;

    //local manupulation
    public RecipeSerialNo:number;
    public ProductName:string;
    public MealTypeName:string;
    public IsCheckedForFoodLog:boolean;
    public FoodId:number;
}   
