import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap, filter } from 'rxjs/operators';
import { VendorApiModel } from 'src/app/models/ExpenseManager/Vendor/vendor-api-model';
//import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { ProductModel } from 'src/app/models/ExpenseManager/Product/product-model';
import { ProductApiModel } from 'src/app/models/ExpenseManager/Product/product-api-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { FoodLoggerService } from 'src/app/services/food-logger.service';
import { FixedDeposit } from 'src/app/models/ExpenseManager/Product/fixed-deposit';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { ConfigLoaderService } from 'src/app/services/config-loader.service';
import {Title} from "@angular/platform-browser";
import { formatDate } from '@angular/common';
import { AccountModel } from 'src/app/models/ExpenseManager/Accounts/account-model';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 600, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 600, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class ProductComponent implements OnInit {
  public result = '';
  public productList: ProductApiModel[];
  public productListWithFds: ProductApiModel[];
  public unTouchedproductListWithFds: ProductApiModel[];
  productModel: ProductModel;
  model: any;
  searching = false;
  searchFailed = false;
  public isShowFd = false;
  public currentDate = new Date();
  public isValue: number = 0;
  public isRequiredError = 'This field is required';
  public ScratchPadAmount: number;
  public productId = 0;
  public checkedSelectionTotal = 0;
  isMomOnly = false;
  isPrashantOnly = false;
  isRetainFilter = false;
  bankFilterName = '';
  public baseFileRef = "";
  public FileBasePathFold3 ="";
  public FileBasePathAsus6Z ="";
  public FileBasePathCommandCenter ="";
  public FileBasePathLenovoLaptop ="";
  public ProductRelativePath="/Scanned/Products/";
  isCopied=false;
  accountModel: AccountModel;
  constructor(private expenseManagerService: ExpenseManagerService, private foodLoggerService: FoodLoggerService, private route: ActivatedRoute
    , private configLoaderService: ConfigLoaderService,private titleService:Title) { 
    this.baseFileRef = this.configLoaderService.FileBasePath;
    this.FileBasePathFold3 = this.configLoaderService.FileBasePathFold3;
    this.FileBasePathAsus6Z = this.configLoaderService.FileBasePathAsus6Z;
    this.FileBasePathCommandCenter = this.configLoaderService.FileBasePathCommandCenter;
    this.FileBasePathLenovoLaptop = this.configLoaderService.FileBasePathLenovoLaptop;
    this.titleService.setTitle("Add Product");
  }
  @ViewChild('productForm') productForm: HTMLFormElement;
  ngOnInit() {
    this.accountModel = new AccountModel();
    this.productId = Number(this.route.snapshot.queryParamMap.get('productid'));
    if (this.productId != null && this.productId > 0) {
      this.getProductById();
    }

    this.productModel = new ProductModel();
    this.productModel.VendorName = "";
    this.productList = [];
    this.productListWithFds = [];
    this.unTouchedproductListWithFds = [];
    this.productModel.FixedDepositModel = new FixedDeposit();
    this.expenseManagerService.GetProductAllOrByID(0).subscribe(response => {
      this.productList = response;
    });
    this.getAllFds();
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetProductByName1(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatter = (x: { ProductName: string }) => x.ProductName;


  searchVendor = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetVendorByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatterVendor = (x: { VendorName: string }) => x.VendorName;

  selectedItemProduct(item) {
    this.setProductModel(item.item);
  }
  //Old
  // selectedItemProduct(item) {
  //   //this.clickedItem=item.item;
  //   console.log(item);
  //   this.productModel.ProductID = item.item.ProductID;
  //   this.productModel.ProductName = item.item.ProductName;
  //   this.productModel.VendorID = item.item.VendorID;
  //   this.productModel.VendorName = item.item.VendorName;
  //   this.productModel.Amount = item.item.Amount;
  //   this.productModel.ProductAutoID = item.item.ProductAutoID;
  //   this.productModel.IsFood = item.item.IsFood;
  //   this.productModel.IsFD = item.item.IsFixedDeposit;
  //   this.productModel.IsEntertainment = item.item.IsEntertainment;
  //   this.productModel.BrandId = item.item.BrandId;
  //   this.productModel.ProductUniqueName = item.item.ProductUniqueName;
  //   this.productModel.SummaryNotes = item.item.SummaryNotes;
  //   this.productModel.FixedDepositModel = item.item.ExpenseFixedDepositModel;
  //   this.productModel.IsForItemTracker = item.item.IsForItemTracker;
  //   this.productModel.IsActive = item.item.IsActive;
  //   this.productModel.PurchaseDate = item.item.PurchaseDate;
  //   this.productModel.RenewDate = item.item.RenewDate;
  //   this.productModel.IsRenewed = item.item.IsRenewed;

  //   if (this.productModel.FixedDepositModel !== null) {
  //     var depositeDate = new Date(this.productModel.FixedDepositModel.DepositeDate);
  //     var maturityDate = new Date(this.productModel.FixedDepositModel.MaturityDate);
  //     this.productModel.FixedDepositModel.DepositeDateDisplay = { year: depositeDate.getFullYear(), month: depositeDate.getMonth() + 1, day: depositeDate.getDate() };
  //     this.productModel.FixedDepositModel.MaturityDateDisplay = { year: maturityDate.getFullYear(), month: maturityDate.getMonth() + 1, day: maturityDate.getDate() };
  //   }
  //   //this.productModel.FixedDepositModel.MaturityDateDisplay = { hour: maturityDate.getHours(), minute: maturityDate.getMinutes() };
  //   if (this.productModel.PurchaseDate) {
  //     var depositeDate = new Date(this.productModel.PurchaseDate);
  //     var maturityDate = new Date(this.productModel.RenewDate);
  //     this.productModel.PurchaseDateDisplay = { year: depositeDate.getFullYear(), month: depositeDate.getMonth() + 1, day: depositeDate.getDate() };
  //     this.productModel.RenewDateDisplay = { year: maturityDate.getFullYear(), month: maturityDate.getMonth() + 1, day: maturityDate.getDate() };
  //   }
  // }
  selectedItemVendor(item) {
    //this.clickedItem=item.item;
    console.log(item);
    //this.vendorModel.VendorAutoID = item.item.VendorAutoID;
    this.productModel.VendorID = item.item.VendorID;
    // this.vendorModel.VendorName = item.item.VendorName;npm
  }
  // Brand
  searchBrand = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.foodLoggerService.GetBrandsByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatterBrand = (x: { Name: string }) => x.Name;

  selectedItemBrand(item) {
    //this.clickedItem=item.item;
    console.log(item);
    //this.vendorModel.VendorAutoID = item.item.VendorAutoID;
    this.productModel.BrandId = item.item.BrandId;
    this.productModel.BrandName = item.item.Name;
    // this.vendorModel.VendorName = item.item.VendorName;
  }


  onSubmit() {
    var isValid = true;
    if ((!this.productModel.ProductName || this.productModel.ProductName.length <= 0)) {
      //this.foodLogModel.ServingQuantity =0;
      this.productForm.form.controls['productModel.ProductName'].setErrors({ 'Required1': this.isRequiredError });
      isValid = false;
    }
    else if (this.productModel.ProductName.length > 495) {
      this.productForm.form.controls['productModel.ProductName'].setErrors({ 'Length': 'Max lenth of 495 exceeded' });
      isValid = false;
    }
    if (!isValid) {
      return 0;
    }
    var productApiModel = new ProductApiModel();
    productApiModel.ProductID = this.productModel.ProductID;
    productApiModel.ProductName = this.productModel.ProductName;
    productApiModel.VendorID = this.productModel.VendorID;
    productApiModel.Amount = this.productModel.Amount;
    productApiModel.ProductAutoID = this.productModel.ProductAutoID;
    productApiModel.BrandId = this.productModel.BrandId;
    productApiModel.IsFood = this.productModel.IsFood;
    productApiModel.IsFixedDeposit = this.productModel.IsFD;
    productApiModel.IsEntertainment = this.productModel.IsEntertainment;
    productApiModel.SummaryNotes = this.productModel.SummaryNotes;
    productApiModel.ProductUniqueName = this.productModel.ProductUniqueName;
    productApiModel.IsForItemTracker = this.productModel.IsForItemTracker;
    productApiModel.IsForShoppingBasket = this.productModel.IsForShoppingBasket;
    productApiModel.IsForFoodLogging = this.productModel.IsForFoodLogging;
    productApiModel.AccountCode=this.productModel.AccountCode;
    productApiModel.AccountName=this.productModel.AccountName;
    productApiModel.IsActive = this.productModel.IsActive;
    productApiModel.PurchaseDate = this.productModel.PurchaseDate;
    productApiModel.RenewDate = this.productModel.RenewDate;
    productApiModel.IsRenewed = this.productModel.IsRenewed;
    productApiModel.UserID = Number(localStorage.getItem('currentUser'));
    if (this.productModel.PurchaseDateDisplay) {
      var newDatePurchase = new Date(this.productModel.PurchaseDateDisplay.year, this.productModel.PurchaseDateDisplay.month - 1
        , this.productModel.PurchaseDateDisplay.day, 0, 0);
      //this.productModel.FixedDepositModel.DepositeDateDisplay = newDate;

      var newDateRenew = new Date(this.productModel.RenewDateDisplay.year, this.productModel.RenewDateDisplay.month - 1
        , this.productModel.RenewDateDisplay.day, 0, 0);
      //this.productModel.FixedDepositModel.DepositeDateDisplay = newDate;

      productApiModel.ExpenseFixedDepositModel = new FixedDeposit();
      productApiModel.PurchaseDateDisplay = newDatePurchase;
      productApiModel.RenewDateDisplay = newDateRenew;
    }
    if (productApiModel.IsFixedDeposit) {
      var newDate = new Date(this.productModel.FixedDepositModel.DepositeDateDisplay.year, this.productModel.FixedDepositModel.DepositeDateDisplay.month - 1
        , this.productModel.FixedDepositModel.DepositeDateDisplay.day, 0, 0);
      //this.productModel.FixedDepositModel.DepositeDateDisplay = newDate;

      var newDate1 = new Date(this.productModel.FixedDepositModel.MaturityDateDisplay.year, this.productModel.FixedDepositModel.MaturityDateDisplay.month - 1
        , this.productModel.FixedDepositModel.MaturityDateDisplay.day, 0, 0);
      //this.productModel.FixedDepositModel.MaturityDateDisplay = newDate1;

      productApiModel.ExpenseFixedDepositModel = new FixedDeposit();
      productApiModel.ExpenseFixedDepositModel = this.productModel.FixedDepositModel;
      productApiModel.ExpenseFixedDepositModel.DepositeDateDisplay = newDate;
      productApiModel.ExpenseFixedDepositModel.MaturityDateDisplay = newDate1;
    }
    // productApiModel.ExpenseFixedDepositModel.Amount = this.productModel.Amount;



    this.expenseManagerService.SaveOrUpdateProduct(productApiModel).subscribe(
      response => {
        console.log(response);
        this.productModel = new ProductModel();
        this.result = response.ProductID +" - "+ formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
        this.productModel.VendorName = "";
        this.productList = [];
        this.productModel.FixedDepositModel = new FixedDeposit();

        this.expenseManagerService.GetProductAllOrByID(0).subscribe(response => {
          this.productList = response;
        });
        this.getAllFds();
      }
    );
  }
  getAllFds() {
    this.expenseManagerService.GetAllFds().subscribe(response => {
      this.unTouchedproductListWithFds = response;
      this.productListWithFds = response;
      this.productListWithFds.sort((a, b) => (a.ExpenseFixedDepositModel.MaturityDate < b.ExpenseFixedDepositModel.MaturityDate ? -1 : 1));

      this.productListWithFds.forEach(
        list => {
          if (new Date(list.ExpenseFixedDepositModel.MaturityDate) < new Date(this.currentDate)) {
            list.isMatured = true;
          }
          list.IsCheckedForTotal = false;
        }

      );

    });
  }
  filterFds(filterValue) {
    this.isShowFd = true;
    var models;
    var currentDateNow = this.currentDate;
    if (filterValue == 'clear') {
      models = this.unTouchedproductListWithFds;
    }
    if (filterValue === 'isnotmatured') {
      models = this.productListWithFds.filter(
        function (e) {
          return new Date(e.ExpenseFixedDepositModel.MaturityDate) > new Date(currentDateNow);
        }
      );
    }
    if (filterValue === 'mom' && this.isMomOnly) {
      if (this.isShowFd) {
        models = this.productListWithFds.filter(
          function (e) {
            return e.ProductName.toLowerCase().indexOf('mom') >= 0 || e.ProductName.toLowerCase().indexOf('sumita') >= 0;
          }
        );
      }
    }
    else if (filterValue === 'me' && this.isPrashantOnly) {
      if (this.isShowFd) {
        models = this.productListWithFds.filter(
          function (e) {
            return e.ProductName.toLowerCase().indexOf('mom') < 0 && e.ProductName.toLowerCase().indexOf('sumita') < 0;
          }
        );
      }
    }

    else {
      models = this.unTouchedproductListWithFds;
      if (filterValue === 'isnotmatured') {
        models = this.productListWithFds.filter(
          function (e) {
            return new Date(e.ExpenseFixedDepositModel.MaturityDate) > new Date(currentDateNow);
          }
        );
      }
    }

    this.productListWithFds = models;
  }
  showHideFD() {
    this.isShowFd = !this.isShowFd;
  }
  toggle(num) {
    this.isValue = num;
  }
  scratchPadClick(choice) {
    if (choice === 'plus') {
      this.productModel.Amount = Number(this.productModel.Amount) + Number(this.ScratchPadAmount);
    }
    else if (choice === 'minus') {
      this.productModel.Amount = Number(this.productModel.Amount) - Number(this.ScratchPadAmount);

    }
    else if (choice === 'multiply') {
      this.productModel.Amount = Number(this.productModel.Amount) * Number(this.ScratchPadAmount);
    }
    else if (choice === 'divide') {
      this.productModel.Amount = Number(this.productModel.Amount) / Number(this.ScratchPadAmount);
    }
  }
  changeProductName() {
    this.result = '';
  }
  fixedDepositeChanged() {
    if (this.productModel.IsFD) {
      if (!this.productModel.FixedDepositModel) {
        this.productModel.FixedDepositModel = new FixedDeposit();
      }
    }
  }
  getProductById() {
    this.expenseManagerService.GetProductAllOrByID(this.productId).subscribe(response => {
      var me = response[0];
      this.setProductModel(response[0]);

    });
  }
  getProductDetails(productAutoID) {
    this.productId = productAutoID;
    this.getProductById();
  }
  setProductModel(item) {
    console.log(item);
    this.productModel = item;
    this.productModel.FixedDepositModel = item.ExpenseFixedDepositModel;
    this.productModel.IsFD = item.IsFixedDeposit;
    if (this.productModel.FixedDepositModel) {
      var depositeDate = new Date(this.productModel.FixedDepositModel.DepositeDate);
      var maturityDate = new Date(this.productModel.FixedDepositModel.MaturityDate);
      this.productModel.FixedDepositModel.DepositeDateDisplay = { year: depositeDate.getFullYear(), month: depositeDate.getMonth() + 1, day: depositeDate.getDate() };
      this.productModel.FixedDepositModel.MaturityDateDisplay = { year: maturityDate.getFullYear(), month: maturityDate.getMonth() + 1, day: maturityDate.getDate() };
    }
    //this.productModel.FixedDepositModel.MaturityDateDisplay = { hour: maturityDate.getHours(), minute: maturityDate.getMinutes() };
    if (this.productModel.PurchaseDate) {
      var depositeDate = new Date(this.productModel.PurchaseDate);
      var maturityDate = new Date(this.productModel.RenewDate);
      this.productModel.PurchaseDateDisplay = { year: depositeDate.getFullYear(), month: depositeDate.getMonth() + 1, day: depositeDate.getDate() };
      this.productModel.RenewDateDisplay = { year: maturityDate.getFullYear(), month: maturityDate.getMonth() + 1, day: maturityDate.getDate() };
    }



  }
  checkedTotal() {
    var me = this.productListWithFds;
    this.checkedSelectionTotal = this.productListWithFds.filter(
      function (e) {
        return e.IsCheckedForTotal == true;
      }
    ).reduce((sum, current) => sum + current.Amount, 0);
    //  var  sum = models.reduce((sum, current) => sum + current.Amount, 0);
    // this.isShowFd = true;
    // var models;
    // var currentDateNow = this.currentDate;
    // if (filterValue == 'clear') {
    //   models = this.unTouchedproductListWithFds;
    // }
    // if (filterValue === 'isnotmatured') {
    //   models = this.productListWithFds.filter(
    //     function (e) {
    //       return new Date(e.ExpenseFixedDepositModel.MaturityDate) > new Date(currentDateNow);
    //     }
    //   );
    // }
    // this.productListWithFds = models;

  }
  searchByProductName() {

    var newModels = [];
    if (!this.isRetainFilter) {
      if (this.isShowFd) {
        newModels = this.unTouchedproductListWithFds;
      }
    }
    else {
      newModels = this.productListWithFds;
    }
    if (this.isShowFd) {
      let term = this.bankFilterName;
      newModels = newModels.filter(function (tag) {
        return tag.ProductName.toLowerCase().indexOf(term) >= 0;
      });
    }
    this.productListWithFds = newModels;
  }
  clearTotalCheckboxes(type) {

    this.productListWithFds.forEach(element => {
      if (type === "none") {
        element.IsCheckedForTotal = false;
      }
      else {
        element.IsCheckedForTotal = true;
      }
    });
    this.checkedTotal();

  }
  /*Account section */
  searchAccount = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term => term.length < 2 ? of([]) :
      (this.expenseManagerService.GetAccountByName(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })))
    ),
    tap(() => this.searching = false)
  )
formatterAccount = (x: { AccountName: string }) => x.AccountName;

selectedItemAccount(item) {
  //this.clickedItem=item.item;
  this.productModel.AccountCode = item.item.AccountCode;
  this.productModel.AccountName = item.item.AccountName;
}


}
