<fieldset class="section-legend-border col-md-12 col-lg-12" #HabitReport >
  <legend class="section-legend-border">Habit Report</legend>
<div class="form-row">
    <div class="form-group col-sm-12 col-md-6 col-lg-3">
        <label for="exampleInputPassword1">Log Date</label>
        <div class="input-group" id="calender-input">
    
          <input class="form-control" placeholder="yyyy-mm-dd" name="LogDateDisplay" [(ngModel)]="LogDateDisplay"
            ngbDatepicker #d="ngbDatepicker"  (ngModelChange)="onModelChange($event)">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12 col-md-6 col-lg-2">
        <div id="time-picker-section">
          <ngb-timepicker name="LogTimeDisplay" [(ngModel)]="LogTimeDisplay">
          </ngb-timepicker>
        </div>
      </div>
  </div>
  <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #SortFilter >
    <legend class="section-legend-border">Sort Filter</legend>
    <div class="form-group row">
      <!-- <div id="sort-by-complete">
        <label for="exampleInputPassword1">Sort By Complete </label>
        <div class="d-flex">
  
          <div class="custom-link span-menu" (click)="filterIscompleted('asc')">
            Asc
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('dsc')">
            Dsc
          </div>
        </div>
      </div> -->
      <div id="sort-by-name" class="col-md-2">
        <label for="exampleInputPassword1">Sort By Name</label>
        <div class="d-flex">
  
          <div class="custom-link span-menu" (click)="filterIscompleted('nasc')">
            Asc
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('ndsc')">
            Dsc
          </div>
        </div>
      </div>
      <div id="sort-by-name"  class="col-md-2">
          <label for="exampleInputPassword1">Sort By HabitType</label>
          <div class="d-flex">
    
            <div class="custom-link span-menu" (click)="filterIscompleted('htasc')">
              Asc
            </div>
            <div class="custom-link span-menu" (click)="filterIscompleted('htdsc')">
              Dsc
            </div>
          </div>
        </div>
  
    </div>
  </fieldset>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #HabitReportList >
  <legend class="section-legend-border">Habit Report List</legend>
<div class="row productlist-section" id="habitReport">
 
    <!-- <div class="form-group col-sm-12 col-md-7 col-lg-4">
        <label for="exampleInputPassword1">Filter By</label>
        <div class="d-flex">
    
          <div class="custom-link span-menu" (click)="filterIscompleted(0)">
            0
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted(50)">
            50
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted(75)">
            75
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted(100)">
            100
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('Re')">
            Re
          </div>
          <div class="custom-link span-menu" (click)="filterIscompleted('R')">
            R
          </div>
        </div>
      </div> -->
    <div class="col-sm-12 example-section">
        <div class="">
            <table class="table report-table">
                <thead>
                    <tr>
                        <th class="">S.No.</th>
                        <th class="first-column">Habit Name</th> <th>Type</th>
                        <th class="view-click-hover" *ngFor="let item of allMonthDates">
                            {{item.getDate()}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <!-- <tr *ngFor="let habitData of allHabitData"> -->
                        <tr *ngFor="let habitData of habitLogs; let i = index;">
                            <td>{{i + 1}}.</td>
                        <td>{{habitData.HabitName}}</td><td>{{habitData.HabitTypeName}}</td>
                        <td *ngFor="let item of allMonthDates" class="report-td">
                            <!-- <span *ngIf="(item.getDate() == logModel.LogDate.getDate())">{{logModel.TotalMinsToDo}}</span> -->
                            <ng-container *ngFor="let logModel of habitLogModelList">
                                <ng-template #donSoFar>
                                    <div class="">
                                      <div> <span class="tool-tip-heading">{{habitData.HabitName}}({{habitData.HabitTypeName}}) </span>
                                        <span>{{ logModel.TotalMinsDoneSoFar}}/{{ logModel.TotalMinsToDo}}</span>
                                      </div>
                        
                                    </div>
                                  </ng-template>
                                  <ng-template #todohabit>
                                    <div class="">
                                      <div>{{logModel.ToolTipToDo}} </div>
                                      <div>{{logModel.HabitName}} </div>
                                    </div>
                                  </ng-template>

                                <!-- <span *ngIf="item.getDate() == logModel.LogDate.getDate() && item.getMonth() == logModel.LogDate.getMonth()
                                && habitData.HabitId == logModel.HabitId">{{logModel.Completed}}</span> -->
                                <div *ngIf="item.getDate() == logModel.LogDate.getDate() && item.getMonth() == logModel.LogDate.getMonth()
                                && habitData.HabitId == logModel.HabitId"
                                class="habit-log-col col-circle">
                                    <div class="circle-section" triggers="click:blur" data-html="true"
                                      placement="right" [ngbTooltip]="donSoFar">
                                      <!-- <div class="c100 p50 small"> -->
                                      <div [className]="'c100 small p'+logModel.Completed">
                                        <span>{{logModel.Completed}}%</span>
                                        <div class="slice">
                                          <div class="bar"></div>
                                          <div class="fill"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                            </ng-container>
                        </td>
                      
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</fieldset>

<fieldset class="section-legend-border col-md-12 col-lg-12" #Habitlogslist >
  <legend class="section-legend-border">Habit logs list</legend>
<div class="row habitloglist-section" id="habitloglist">
    <div class="col-sm-12 example-section">
        <div class="">
            <table class="table">
                <thead>
                    <tr>
                        <th class="">S.No.</th>
                        <th scope="col">Habit Name</th>
                        <th scope="col">Type</th>
                        <th scope="col">Completed</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of habitLogModelList; let i = index;">
                        <th scope="row" >{{i + 1}}.</th>
                        <th scope="row" class="view-click-hover">{{item.HabitName}}</th>
                        <td>{{item.HabitTypeName}}</td>
                        <td>{{item.Completed}}</td> 
                        <td>{{item.LogDate| date: 'fullDate'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</fieldset>
<!-- <div class="habit-log-col col-circle">
    <div class="circle-section" triggers="click:blur" data-html="true"
      placement="right">
      <div [className]="'c100 small p'+60">
        <span>60%</span>
        <div class="slice">
          <div class="bar"></div>
          <div class="fill"></div>
        </div>
      </div>
    </div>
  </div> -->
