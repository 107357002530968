<div id="voucherDetailsPopup">
 
<div id="voucherDetailsPopup-content">
    <fieldset class="section-legend-border col-md-12 col-lg-12" #voucherDetails >
        <legend class="section-legend-border">VoucherId: {{voucherID}} - {{transactionMasterModel.VendorName}}   </legend>
      <!-- <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        <div id="voucherDetailsPopup-CloseButton">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="fa fa-times" aria-hidden="true"></i></button>
        </div>
            <div class="row" id="voucher-details-popup">
                <div class="col-sm-12 example-section">
                  <div class="">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">AccountCode</th>
                          <th scope="col">ProductName</th>
                          <th scope="col">Cr</th>
                          <th scope="col">Dr</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of transactionMasterModel.ExpenseTTransModel">
                          <th scope="row">{{item.AccountCode}}</th>
                          <td> {{item.ProductName}}</td>
                          <td><span *ngIf="item.TranType=='D'">{{item.Amount}}</span></td>
                          <td><span *ngIf="item.TranType=='C'">{{item.Amount}}</span></td>
                        </tr>
            
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>{{crTotal|customInr}}</td>
                          <td>{{drTotal|customInr}}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                </div>
    
                <!-- <div class="row" id="voucherDetailsPopup-BottomCloseButton">
                    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
                </div> -->
      </div>
    
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
      </div> -->
    </fieldset>
</div>

</div>

