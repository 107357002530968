<div class="card card-login mx-auto mt-1"  *ngIf='!isLoggedIn'>
    <div class="card-header">Login</div>
    <div class="card-body">
      <form #form="ngForm" (submit)="logForm(form.value)">
        <div class="form-group">
          <div class="form-label-group">
            <input type="email" id="inputEmail" class="form-control" name="emailaddress" ngModel
            placeholder="Email address" required="required" value="{{firstName}}" #inputEmail>
            <label for="inputEmail">Email address</label>
            <!-- {{ email.focus() }} -->
          </div>
        </div>
        <div class="form-group">
          <div class="form-label-group">
            <input type="password" id="inputPassword" name="password" ngModel
            class="form-control" placeholder="Password" required="required"  #passwordfield>  <span (click)="passwordfield.type=passwordfield.type=='password'?'text':'password'" 
            class="fa fa-fw field-icon toggle-password"
                [ngClass]="(passwordfield.type=='password')?' fa-eye':' fa-eye-slash'"></span><!--Show hide password-->
            <label for="inputPassword">Password</label>
          </div>
        </div>
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input type="checkbox" value="remember-me">
              Remember Password
            </label>
          </div>
        </div>
        <!-- <a class="btn btn-primary btn-block" href="index.html">Login</a> -->
        <button type="submit" class="btn btn-primary btn-block">Login</button>
      </form>
      <div class="text-center">
        <span class="loginError error">{{loginErrorMessage}}</span>
      </div>
      <div class="text-center">
        <a class="d-block small mt-3" href="register.html">Register an Account</a>
        <a class="d-block small" href="forgot-password.html">Forgot Password?</a>
      </div>
    </div>

  </div>
  <div class="card card-login mx-auto mt-1"  *ngIf='isLoggedIn'>
    <div class="card-header">Login</div>
    <div class="form-group">
      <div class="form-label-group">
        <a href="#" type="button" class="btn btn-primary btn-block" (click)="Logout()"> Logout </a>
      </div>
    </div>
  </div>
