<fieldset class="section-legend-border col-md-12 col-lg-12" #Account >
  <legend class="section-legend-border">Account</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
      <div class="form-row">
        <div class="form-group col-md-6">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.AccountName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Account:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="accountModel.AccountAutoComplete" 
          [(ngModel)]="accountModel.AccountAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
          [resultTemplate]="rt" (selectItem)="selectedItem($event)"
          />
        </div>
      </div>
 
      <div class="form-row">
      <div class="form-group col-sm-12 col-md-4 col-lg-4">
        <label for="voucherType">Account Master</label>
        <select class="form-control" name="accountModel.AccountMasterID1" [(ngModel)]="accountModel.AccountMasterID" (change)='onOptionsSelected($event)'>
          <option [ngValue]="undefined" disabled  selected> Select A/c Master </option>
          <option *ngFor="let item of accountMasterModels" [value]="item.AccountMasterID">
            {{item.AccountMasterName}}
          </option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-4 col-lg-4">
        <label for="disabledTextInput">Account Master ID</label>
        <input type="text" [(ngModel)]="accountModel.AccountMasterID" name="accountModel.AccountMasterID" placeholder="Account Master ID" class="form-control" disabled>
      </div>
      <div class="form-group col-sm-12 col-md-4 col-lg-4">
        <label for="disabledTextInput">Account Master Name</label>
        <input type="text" [(ngModel)]="accountModel.AccountMasterName" name="accountModel.AccountMasterName" placeholder="Account Master Name" class="form-control" disabled>
      </div>
    </div>
      <div class="form-row"> 
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">Account ID</label>
          <input type="text" [(ngModel)]="accountModel.AccountID" name="accountModel.AccountID" placeholder="account id" class="form-control" disabled>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">Account Code</label>
          <input type="text" [(ngModel)]="accountModel.AccountCode" name="accountModel.AccountCode" placeholder="Account Code" class="form-control">
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">Account Name</label>
          <input type="text" [(ngModel)]="accountModel.AccountName" name="accountModel.AccountName" placeholder="Account Name" class="form-control">
        </div>
    
      </div>
      <div class="form-row"> 
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="voucherType">Cash Bank Other</label>
          <select class="form-control" name="transactionModel.VoucherType" [(ngModel)]="accountModel.CashBankOther" (change)='onCorBptionsSelected($event)'>
            <option [ngValue]="undefined" disabled  selected> Select C B Other  </option>
            <option *ngFor="let item of cbOther" [value]="item.Value">
              {{item.Text}}
            </option>
          </select>
        </div>
     
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">Cash Bank Other</label>
          <input type="text" [(ngModel)]="accountModel.CashBankOther" name="accountModel.CashBankOther" placeholder="Cash Bank Other" class="form-control" disabled>
        </div>
    
      </div>
      <div class="form-row"> 
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="voucherType">Select A/C Status</label>
          <select class="form-control" name="accountModel.AccountStatus1" [(ngModel)]="accountModel.AccountStatus" (change)='onAccountStatusSelected($event)'>
            <option [ngValue]="undefined" disabled  selected> Select Account Status  </option>
            <option *ngFor="let item of accountStatus" [value]="item.Value">
              {{item.Text}}
            </option>
          </select>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">A/C Status</label>
          <input type="text" [(ngModel)]="accountModel.AccountStatus" name="accountModel.AccountStatus" placeholder="Account Status" class="form-control" disabled>
        </div>
    
  
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <label for="disabledTextInput">A/C Auto ID</label>
          <input type="text" [(ngModel)]="accountModel.AccountAutoID" name="accountModel.AccountAutoID" placeholder="Account Auto ID" class="form-control" disabled>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <div class="form-check form-check-inline"> 
            <label for="disabledTextInput">Receipt</label>
            <div class="checkbox-item">
              <input type="checkbox" [(ngModel)]="accountModel.Receipt" name="accountModel.Receipt" class="form-check-input">
            </div>
          </div>
         
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <div class="form-check form-check-inline">
            <label for="disabledTextInput">Active</label>
            <div class="checkbox-item">
            <input type="checkbox" [(ngModel)]="accountModel.Active" name="accountModel.Active" class="form-check-input">
          </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        <div class="form-group col-md-4 col-sm-12">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
      </div>
    </form> 
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
  </div>  
  </fieldset>
  <div class="row" id="topic-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">Vendor List</p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">AccountID</th>
              <th scope="col">AccountCode</th>
              <th scope="col">AccountMasterName</th>
              <th scope="col">AccountStatus</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of accountApiModel">
              <th scope="row">{{item.AccountID}}</th>
              <td>{{item.AccountCode}}</td>
              <td>{{item.AccountMasterName}}
                <td>{{item.AccountStatus}}</td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
	
  <!-- <pre>Model: {{ accountModel.AccountAutoComplete | json }}</pre> -->