<fieldset class="section-legend-border col-md-12 col-lg-12" #ManageItemCurrentLocation >
    <legend class="section-legend-border">Manage Item Current Location</legend>
<div class="row">
    <div class="col-sm-12">
        <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
            <!-- <div class="form-row">
                <div class="form-group col-md-6">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.LocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Brand:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="currentLocationModel.ItemTrackerLocationAutoComplete" [(ngModel)]="currentLocationModel.ItemTrackerLocationAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
            </div> -->
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-6 col-lg-6">
                    <ng-template #rtCurrentLocation let-r="result" let-t="term">
                        {{ r.ProductName || r.ProductUniqueName}}
                    </ng-template>
                    <label for="typeahead-basic">Search  By Productname: if mapping exist</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="currentLocationModel.ItemTrackerCurrentLocationProductAutoComplete" [(ngModel)]="currentLocationModel.ItemTrackerCurrentLocationProductAutoComplete" [ngbTypeahead]="searchCurrentLocation" [inputFormatter]="formatterCurrentLocation" [resultTemplate]="rtCurrentLocation" (selectItem)="selectedItemCurrentLocation($event)"
                    />
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4">
                    <label for="disabledTextInput">Item Current Location ID</label>
                    <input type="text" [(ngModel)]="currentLocationModel.ItemCurrentLocationID" name="currentLocationModel.ItemCurrentLocationID" placeholder="id" class="form-control">
                   
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-6 col-lg-6">
                    <ng-template #rt let-r="result" let-t="term">
                        {{ r.LocationName }} | {{ r.LocationUniqueName }} 
                    </ng-template>
                    <label for="typeahead-basic">Search for a Sub Location:</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="locationModel.ItemTrackerLocationAutoComplete" [(ngModel)]="locationModel.ItemTrackerLocationAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItem($event)"
                    />
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4">
                    <label for="disabledTextInput">Location ID</label>
                    <input type="text" [(ngModel)]="currentLocationModel.LocationID" name="currentLocationModel.LocationID" placeholder="Location ID " class="form-control">
                    {{currentLocationModel.LocationName}}
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-6 col-lg-6">
                    <ng-template #rtMainLocation let-r="result" let-t="term">
                        {{ r.LocationName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Main Location:Ex. Room</label>
                    <input id="typeahead-basic" type="text" class="form-control" name="locationModel.ItemTrackerMainLocationAutoComplete" [(ngModel)]="locationModel.ItemTrackerMainLocationAutoComplete" [ngbTypeahead]="searchMainLocation" [inputFormatter]="formatterMainLocation" [resultTemplate]="rtMainLocation" (selectItem)="selectedItemMainLocation($event)"
                    />
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4">
                    <label for="disabledTextInput">Main Location ID</label>
                    <input type="text" disabled [(ngModel)]="currentLocationModel.MainLocationID" name="currentLocationModel.MainLocationID" placeholder="Main Location ID" class="form-control">
                    {{currentLocationModel.MainLocationName}}
                </div>
            </div>
           
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-6 col-lg-6">
                    <ng-template #rtProduct let-r="result" let-t="term">
                      {{ r.ProductName}}
                    </ng-template>
                    <label for="typeahead-basic">Search for a Product: For new mapping too</label>
                    <input  id="typeahead-basic" type="text" class="form-control" name="ProductAutoComplete"
                      [(ngModel)]="ProductAutoComplete" [ngbTypeahead]="searchProduct" [inputFormatter]="formatter"
                      [resultTemplate]="rtProduct" (selectItem)="selectedItemProduct($event)" />
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4">
                    <label for="disabledTextInput">Item ID</label>
                    <input  type="text" [(ngModel)]="currentLocationModel.ItemID" name="currentLocationModel.ItemID" placeholder="id" class="form-control">
                    {{currentLocationModel.ProductName}}
                </div>
          
            </div>
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-6 col-lg-6">
                    <qr-code [value]="currentLocationModel.ProductUniqueName" [size]="qrwidth"></qr-code>
                </div>
            </div>
            <div class="form-row" *ngIf="currentLocationModel.ProductUniqueName != undefined" id="prd-file-section">
                <div class="col-sm-12 col-md-12">
                 <span>Product Image: </span> <a href="{{baseFileRef}}{{currentLocationModel.ProductUniqueName}}.jpg" target="_blank">{{currentLocationModel.ProductUniqueName}}.jpg</a>
                </div>
                <div class="col-sm-12 col-md-12">
                    <span>Location: </span><a href="/itemtracker/itemlistbylocation?locationname={{currentLocationModel.SubLocationUniqueName}}" target="_blank">{{currentLocationModel.SubLocationUniqueName}}</a>
                </div>  
                <div class="col-sm-12 col-md-12">
                  <span>Location Path: </span>  <span>{{currentLocationResponse}} </span>
                </div>
                <div class="col-sm-12 col-md-12">
                    <img src="{{baseFileRef}}{{currentLocationModel.ProductUniqueName}}.jpg" width="200"/>
                </div>
            </div>	
          
           
            <div class="form-row">
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4">
                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                </div>
                <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
            </div>
        </form>
    </div>
    <div class="col-sm-12">
        {{result}}
      </div>
</div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #ItemCurrentList >
    <legend class="section-legend-border">Item Current List</legend>
<div class="row" id="Item-Current-List">
    <div class="col-sm-12 example-section">
      <div class="">
        <p class="section-heading-text">Qr code Size For ex 150</p>
        <p>   <input type="text" [(ngModel)]="qrwidth" name="qrwidth" placeholder="Qr code Size" class="form-control"></p>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item Current Loc ID</th>
              <th scope="col">Item ID</th>
              <th scope="col">Prd Name</th>
              <th scope="col">Location ID</th>
              <th scope="col">Location Name</th>
              <th scope="col">Main Location ID</th> 
              <th scope="col">Main Location Name</th>
              <th scope="col">Location UniqueName</th>
              <th scope="col">LocationNameForQrCode</th>
              <th scope="col">Product UniqueName For Qr Code</th>
              <th scope="col">Product UniqueName </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of itemTrackerCurrentLocationList">
              <th scope="row">{{item.ItemCurrentLocationID}}</th>
              <td>{{item.ItemID}}</td>
              <td>{{item.ProductName}}</td>
              <td >{{item.LocationID}}</td>
              <td >{{item.LocationName}}</td>
              <td>{{item.MainLocationID}}</td> 
              <td>{{item.MainLocationName}}</td>
              <td>
                <qr-code [value]="item.LocationNameForQrCode" [size]="qrwidth"></qr-code>
              </td>
              <td>{{item.LocationNameForQrCode}}</td>
              <td>
                <qr-code [value]="item.ProductUniqueName" [size]="qrwidth"></qr-code>
              </td>
              <td>
                {{item.ProductUniqueName}}
              </td>
          </tbody>
        </table>
      </div>
    </div>
    </div>
</fieldset>