<div class="row">
  <div class="col-lg-4">
    <!-- <div class="heading-dashboard1"><h1>DASHBOARD</h1> </div> -->
  </div>
  <div class="col-lg-4 last-updated">{{lastUpdated}}</div>
  <div class="col-lg-4">
    <!-- <div class="heading-dashboard1"><h1>DASHBOARD</h1> </div> -->
  </div>
</div>
<div class="row">

  <div class="col-lg-4">
    <div class="two-circle">
      <div class="large-circle-semi_arc large-circle">
        <div class="large-circle-semi_arc_2 large-circle-semi_arc_e4_1">
          <div class="counterspin4"></div>
        </div>
        <div style="font-size: 70px; margin-top: 25px;">{{ currentTime | date:'d'}}</div>
        <div style="font-size: 37px;">{{ currentTime | date:'MMMM'}}</div>
      </div>
<!--https://v17.angular.io/api/common/DatePipe-->
      <div class="arc-small-circle small-circle-e1 small-circle">
        <div style="font-size: 29px; margin-left: -10px; margin-top: 23px;"> {{ currentTime | date:'h:mm a'}}</div>
        <div style="font-size: 27px; margin-left: 40px; margin-top: -30px; display: inline;">{{ currentTime | date:'ss'}}</div>
        <div style="font-size: 25px; margin-top: 10px;">{{ currentTime | date:'EEEE'}}</div>
      </div>
    </div>
  </div>
  <div class="col-lg-4"></div>
  <div class="col-lg-4">
    <div class="google-calender">
    <iframe src="https://calendar.google.com/calendar/embed?height=300&wkst=1&ctz=Asia%2FKolkata&showPrint=0&showTabs=0&showTz=0&mode=MONTH&src=cHJhc2hhbnRhdHdlYkBnbWFpbC5jb20&color=%23039BE5" 
    style="border-width: 7px;border-radius: 21px;border-color: #02feff;" width="470" height="250" frameborder="0" scrolling="no"></iframe>
  </div>
  </div>
  <!-- <div class="col-lg-4">
    <div class="two-circle">
      <div class="large-circle-semi_arc large-circle">
        <div class="large-circle-semi_arc_2 large-circle-semi_arc_e4_1">
          <div class="counterspin4"></div>
        </div>
        <div style="font-size: 40px; margin-top: 25px;">04</div>
        <div style="font-size: 25px;">January</div>
      </div>

      <div class="arc-small-circle small-circle-e1 small-circle">
       
        <div style="font-size: 23px; margin-left: -10px; margin-top: 23px;">23:41</div>
        <div style="font-size: 15px; margin-left: 40px; margin-top: -30px; display: inline;">31</div>
        <div style="font-size: 17px; margin-top: 10px;">Tuesday</div>
      </div>
    </div>
  </div> -->

</div>

<div class="row top-buffer bottom-buffer">
  <div class="col-lg-4">
   
    <div class="two-circle">
      <div class="large-circle-semi_arc large-circle">
        <div class="large-circle-semi_arc_2 large-circle-semi_arc_e4_1">
          <div class="counterspin4"></div>
        </div>
        <!-- <div style="font-size: 40px; margin-top: 25px;">6</div> -->
        <!-- <div class="big-circle-text">R Logics,R C Sharp,R Angular,R Logics,R C Sharp,R Angular,R Angular,R dot net core,R C Sharp,R Angular</div> -->
      <div class="big-circle-text">{{habitNames}}</div>
      </div>

      <!-- <div class="arc-small-circle small-circle-e1 small-circle">
        <div style="font-size: 23px; margin-left: -10px; margin-top: 23px;">{{totalMinsDone}}<span style="font-size: 21px;"> of</span></div>
        <div style="font-size: 28px; margin-left: 40px; margin-top: -30px; display: inline;">{{totaMins}}</div>
        <div style="font-size: 24px; margin-top: 0px;">Minutes Pending</div>
      </div> -->
      <div class="arc-small-circle small-circle-e1 small-circle">
        <div class="small-circle-txt">
        <!-- <div style="font-size: 23px; margin-left: -10px; margin-top: 23px;">{{totalMinsDone}}<span style="font-size: 21px;"> of</span></div> -->
        <div style="font-size: 56px;">{{totaMins}}</div>
        <div style="font-size: 24px; margin-top: 0px;">Minutes Pending</div>
      </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div id="arc-region">
      <!-- Arc Reactor -->
      <div id="arc_container">
        <div class="arc_reactor">
          <div class="case_container">
            <div class="e7">
              <div class="semi_arc_3 e5_1">
                <div class="semi_arc_3 e5_2">
                  <div class="semi_arc_3 e5_3">
                    <div class="semi_arc_3 e5_4"></div>
                  </div>
                </div>
              </div>
              <div class="core2"></div>
            </div>
            <div class="arcBorder">
              <ul class="marks">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
           
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="col-lg-4">
    <div class="two-circle">
      <div class="large-circle-semi_arc large-circle">
        <div class="large-circle-semi_arc_2 large-circle-semi_arc_e4_1">
          <div class="counterspin4"></div>
        </div>
        <div style="font-size: 40px; margin-top: 25px;">04</div>
        <div style="font-size: 25px;">January</div>
      </div>

      <div class="arc-small-circle small-circle-e1 small-circle">
        <div style="font-size: 23px; margin-left: -10px; margin-top: 23px;">23:41</div>
        <div style="font-size: 15px; margin-left: 40px; margin-top: -30px; display: inline;">31</div>
        <div style="font-size: 17px; margin-top: 10px;">Tuesday</div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
   <h1>DASHBOARD</h1>
  </div>
</div>


