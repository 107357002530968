<fieldset class="section-legend-border col-md-12 col-lg-12" #Product>
    <legend class="section-legend-border">Shopping Basket</legend>
    <div class="row">
        <div class="col-sm-12">
       

           

            <div class="row">
                <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Product>
                    <legend class="section-legend-border">Add Product List For Shopping</legend>
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">For updating list - Updated price or qty then deselect and select again to update list</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-8 col-lg-8">
                            <label for="voucherType">Filter product by vendor</label>
                            <select class="form-control" name="selectedVendor" [(ngModel)]="selectedVendor"
                                (change)='onOptionsSelected($event)'>
                                <option [ngValue]="undefined" selected> Select Vendor </option>
                                <option *ngFor="let item of UniqueVendors" [value]="item.VendorID">
                                    {{item.VendorName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12" id="shoppingList">
                            <table class="">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <!-- <th scope="col">S.No.</th> -->
                                        <th scope="col" class="product-th">Name</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Qty Type</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Amt chnge</th>
                                        <th scope="col">AccCode</th>
                                        <th scope="col">Vendor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of shoppingBasketList;let i=index;">
                                        <td>
                                            <input type="checkbox" [(ngModel)]="item.IsCheckedForShopping"
                                                (ngModelChange)="addSelectedItem(item)"
                                                name="item.IsCheckedForShopping{{i}}" class="largerCheckbox">
                                        </td>
                                        <!-- <td>{{i + 1}}.</td> -->
                                        <td scope="row" class="product-td"> {{item.ProductName}}</td>
                                        <td class="quantity-td">
                                            <span (click)="item.Quantity=item.Quantity-250;item.Quantity=item.Quantity<=0?250:item.Quantity"
                                            class="custom-link">
                                            <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                        </span>
                                        <input type="number" [(ngModel)]="item.Quantity" name="item.Quantity{{i}}"
                                            class="td-quantity-input">
                                        <span (click)="item.Quantity=item.Quantity+250" class="custom-link"><i
                                                _ngcontent-bkv-c47="" class="fas fa-plus-circle"></i>
                                        </span>
                                    </td>
                                    <td class="quantity-td">
                                        <!-- <label for="exampleFormControlSelect1">Measurement Type</label> -->
                                        <select class="form-control" id="MeasurementType"
                                            name="item.MeasurementId" class="measure-td-select"
                                            [(ngModel)]="item.MeasurementId">
                                            <option [ngValue]="undefined" disabled selected> Meas. Type </option>
                                            <option *ngFor="let itemMeasureType of measurementModels" [value]="itemMeasureType.MeasurementId">
                                                {{itemMeasureType.MeasurementType}}
                                            </option>
                                        </select>
                                        {{item.MeasurementType}}

                                    </td>
                                       
                                        <td> {{item.Amount|customInr}} </td>
                                    <td><input type="number" [(ngModel)]="item.Amount" name="item.Amount{{i}}"
                                        class="td-quantity-input" /></td>
                                        <td>{{item.AccountCode}}</td>
                                        <td>{{item.VendorName}}</td>
                                    </tr>

                                </tbody>
                            </table>
                            <div class="">
                            </div>
                        </div>
                    </div>
                </div>
                </fieldset>
            </div>

      
            <!-- Dummy list for showing added item begins here-->
            <div class="row" *ngIf="shoppingBasketMasterApiModel.ShoppingBasketApiModels.length > 0">
              

                <fieldset class="section-legend-border sub-section-legend col-sm-12 col-md-12 col-lg-12" #finalPrintSectionView>
                    <legend class="section-legend-border">Shopping List For Printing</legend>
                    <form  #shoppingbasketForm="ngForm">

 <!-- Below section will be used for print-->
                    <div id="finalPrintSection" class="row">
                        <div class="col-sm-12">
                           
                            <div class="row">
                                <div class="col-sm-12 col-md-7 col-lg-7">
                                    <label for="vender">Select Vendor Heading For ex. Shubash bigbask etc</label>
                                    <div *ngIf="!selectedVendorPrintValidation.valid" class="input-error"> {{selectedVendorPrintValidation.errors.Required}} </div>
                                    <select class="form-control" name="selectedVendorPrint"  #selectedVendorPrintValidation="ngModel"
                                        [(ngModel)]="selectedVendorPrint" (change)='onOptionsSelectedPrint($event)'>
                                        <option [ngValue]="undefined" disabled selected> Select Vendor </option>
                                        <option *ngFor="let item of UniqueVendorsPrint" [value]="item.VendorID">
                                            {{item.VendorName}}
                                        </option>
                                    </select>
                                </div>
                               
                            </div>
                            <div class="row" [hidden]="!isPrintViewVisible">
                                <div class="col-sm-12" id="shoppingListForPrint">
                                    <!-- <div style="margin-top: 12px;margin-left: 12px;width: 244px;">
                                        <h4>{{shoppingBasketMasterApiModel.VendorName}}</h4>
                                        <hr>
                                    </div> -->
                                    <table class="tabel">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="product-td"
                                                    style="font-size: 28px;padding-right: 37px;border-bottom: 1px solid black;">
                                                    Item name</th>
                                                <th scope="col"
                                                    style="font-size: 28px;padding-right: 37px;border-bottom: 1px solid black; ">
                                                    Quantity</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let item of shoppingBasketMasterApiModel.ShoppingBasketApiModels;let i=index;">
                                                <ng-container>
                                                    <td scope="row" class="product-td" style="font-size: 22px;
                                                   padding-right: 37px;padding-top: 12px;font-weight: bold;">{{i + 1}}.
                                                        {{item.ProductNamePrint}}</td>
                                                    <td style="font-size: 22px;
                                                   padding-right: 37px;padding-top: 12px;font-weight: bold">{{item.QuantityPrint}}
                                                        {{item.MeasurementTypePrint|titlecase}}</td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="">

                                    </div>
                                </div>
                            </div>
                            <!-- Just for showing purpose printing will happen from above code-->
                            <div class="row" id="dummyShoppingList">
                                <fieldset class="section-legend-border sub-section-legend col-sm-12 col-md-12 col-lg-12" #Product>
                                    <legend class="section-legend-border">Just for Showing {{shoppingBasketMasterApiModel.VendorName}} -Mid -{{shoppingBasketMasterApiModel.ShoppingBasketMasterID}}
                                    </legend>
                                    <div class="col-sm-12" id="shoppingListForPrintColor">
                                        <table class="tabel">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="product-td"> S.No. </th>
                                                    <th scope="col" class="product-td"> Item name</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">A/C Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let item of shoppingBasketMasterApiModel.ShoppingBasketApiModels;let i=index;">
                                                    <ng-container>
                                                        <td><span class="tb-numbering">{{i + 1}}.</span></td>
                                                        <td scope="row" class="product-td"> {{item.ProductName}}</td>
                                                        <td>{{item.Quantity}} {{item.MeasurementType|titlecase}}</td>
                                                        <td>{{item.Amount|customInr}}</td>
                                                        <td>{{item.AccountCode}}</td>
                                                    </ng-container>
                                                </tr>
                                                <tr>
                                                    <td colspan="2"> </td>
                                                    <td>Projected Total: </td>
                                                    <td>{{projectedTotal|customInr}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="">

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-4 col-lg-4 float-left" [hidden]="!IsDbFilterVisible">
                                <button printSectionId="shoppingListForPrint" ngxPrint printTitle="MId-{{this.shoppingBasketMasterApiModel.ShoppingBasketMasterID}}| {{this.shoppingBasketMasterApiModel.VendorName}}_{{currentDateTime}} "
                                    [useExistingCss]="false" class="btn btn-primary btn-block">Print</button>
                                    <!-- <button (click)="exportAsPDF('dummyShoppingList')">Pdf</button> -->
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4 float-right">
                                <button type="button" (click)="saveData()" class="btn btn-primary btn-block"> Save
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-12">{{resultReponse}}</div>
                    </div>
                </form>
                </fieldset>
         
            </div>

                  <!-- Filtering begins here for previous record and print-->
                  <div class="row">
                    <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" name="IsDbFilterVisible"
                            [(ngModel)]="IsDbFilterVisible">
                        <label class="form-check-label" for="exampleCheck1">Show Database Filter </label>
                    </div>
                    <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isPrintViewVisible"
                            [(ngModel)]="isPrintViewVisible">
                        <label class="form-check-label" for="exampleCheck1">Show Printing Table </label>
                    </div>
                </div>
                <div class="row" *ngIf="IsDbFilterVisible == true">
                    <fieldset class="section-legend-border col-md-12 col-lg-12" #Product>
                        <legend class="section-legend-border">Database Filter</legend>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
                                    <input type="checkbox" class="form-check-input" id="isUseUpdateDateDbFilterId" name="isUseUpdateDateDbFilter"
                                        [(ngModel)]="isUseUpdateDateDbFilter">
                                    <label class="form-check-label" for="exampleCheck1">Use Update date </label>
                                </div>
                                <div id="dbFilterId">
        
                                    <label for="VendorType">Select Basket No.</label>
                                   <input type="text"   [(ngModel)]="shoppingBasketMasterIdDbFilter" name="selectedShoppingBasketIdDbFilter"/>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div id="dbFilter">
                                    <label for="VendorType">Select Vendor Last bill</label>
                                    {{this.shoppingBasketMasterApiModel.Created| date:"dd/MM/yyyy hh:mm:ss a":"+0530"}}
                                    <select class="form-control" name="selectedVendorDbFilter"
                                        [(ngModel)]="selectedVendorDbFilter" (change)='onOptionsSelectedDbFilter($event)'>
                                        <option [ngValue]="undefined" selected> Select Vendor </option>
                                        <option *ngFor="let item of UniqueVendorsPrint" [value]="item.VendorID">
                                            {{item.VendorName}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                              <ng-template #rtAccount let-r="result" let-t="term">
                                {{ r.AccountName}}
                              </ng-template>
                              <label for="typeahead-basic">Search for a Account:</label>
                              <input id="typeahead-basic" type="text" class="form-control" name="AccountAutoComplete" 
                              [(ngModel)]="AccountAutoComplete" [ngbTypeahead]="searchAccount" [inputFormatter]="formatterAccount"
                              [resultTemplate]="rtAccount" (selectItem)="selectedItemAccount($event)"
                              />
                            </div>
                            <div class="form-group col-sm-12 col-md-6 col-lg-6">
                              <label for="disabledTextInput">Account Code</label>
                              <input type="text" [(ngModel)]="accountCodeForCreditEntry" name="accountCodeForCreditEntry"
                                placeholder="Account Code" class="form-control" disabled #AccountCodeValidation="ngModel">
                              <span>{{accountNameForCreditEntry}}</span>
                              <!-- <div *ngIf="!AccountCodeValidation.valid"> {{AccountCodeValidation.errors?.Required}} </div> -->
                            </div>
                      
                          </div>
                          <div class="form-row">
                            <div class="col-sm-12">
                                
                                <button type="button" (click)="saveToTransactionTable()" class="btn btn-primary btn-block"> Save To TransactionTable
                                </button>
                            </div>
                           
                        </div>
                          <div class="form-row">
                            <div class="col-sm-12">
                                {{result}}
                            </div>
                          </div>
                     
                    </fieldset>

                   
                </div>

              
             
        </div>
    </div>
</fieldset>