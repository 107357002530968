export class ShoppingBasketApiModel {
    public  ShoppingBasketID:number;
    public  ShoppingBasketMasterID:number;
    public  ProductID:number;
    public  ProductName:string;
    public  Quantity:number;  
    public  Amount:number;
    public  MeasurementId:number;
    public  MeasurementType:string;
    public AccountCode:string;
    
    public  SummaryNotes:string;
    public  UserID:number;
    public IsAvailableToPublic:boolean ;
    public  Created:Date ;
    public  Updated:Date ;

    public  ProductNamePrint:string;
    public  QuantityPrint:number;  
    public  MeasurementTypePrint:string;
}
