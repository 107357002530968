export class BrandModel {
    public BrandId:number;
    public Name:string;
    public IsFood:boolean;
    public UserID:number;
    public IsAvailableToPublic:boolean;

    public BrandAutoComplete:any;
    public Brandlist:BrandModel[];
}
