import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, catchError, tap } from 'rxjs/operators';
import { VendorModel } from 'src/app/models/ExpenseManager/Vendor/vendor-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { VendorApiModel } from 'src/app/models/ExpenseManager/Vendor/vendor-api-model';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.css']
})
export class VendorComponent implements OnInit {
  public vendorModel: VendorModel;

  model: any;
  searching = false;
  searchFailed = false;

  constructor(private expenseManagerService: ExpenseManagerService) { }

  ngOnInit() {
    this.vendorModel = new VendorModel();
    this.vendorModel.Vendorlist = [];

    
    this.expenseManagerService.GetVendorAllOrByID(0).subscribe(response =>{
      this.vendorModel.Vendorlist = response.response;
  }); 
  }
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term => term.length < 2 ? of([]) :
        (this.expenseManagerService.GetVendorByName(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })))
      ),
      tap(() => this.searching = false)
    )



  formatter = (x: { VendorName: string }) => x.VendorName;

  selectedItem(item) {
    //this.clickedItem=item.item;
    console.log(item);
    this.vendorModel.VendorAutoID = item.item.VendorAutoID;
    this.vendorModel.VendorID = item.item.VendorID;
    this.vendorModel.VendorName = item.item.VendorName;
  }

  onSubmit() {
    var formModel = this.vendorModel;
    var vendorApimodel = new VendorApiModel();
    vendorApimodel.vendorAutoID = formModel.VendorAutoID;
    vendorApimodel.vendorID = formModel.VendorID;
    vendorApimodel.vendorName = formModel.VendorName;
    vendorApimodel.UserID = Number(localStorage.getItem('currentUser'));
    this.expenseManagerService.SaveOrUpdateVendor(vendorApimodel).subscribe(
      response => {
        console.log(response);
        this.vendorModel = new VendorModel();
        this.expenseManagerService.GetVendorAllOrByID(0).subscribe(response =>{
          this.vendorModel.Vendorlist = response.response;
      }); 

      }
    );
  }
  getPageName(): string {
    console.log("works");
    return 'Vendor';
  }
}
