<fieldset class="section-legend-border col-md-12 col-lg-12" #DraggedReg id="DraggedRegion">
  <legend class="section-legend-border">Points to remember</legend>
<ol>
  <li>While dragging <b>not mapped item</b> always referesh after dragging one item or they will be duplicated for each item dragged</li>
  <li>Sublocation will listed only if any product is mapped. If not need to map from current location</li>
  <li>Sublocations only visible if there is some item mapped to it.</li>
</ol>
</fieldset>

<fieldset class="section-legend-border col-md-12 col-lg-12" #LocationFilter>
  <legend class="section-legend-border">Location Filter</legend>
<div class="filter-section">


  <form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="form">
    <div class="row">
    <div class="col-lg-12">

      <div class="main-location-section" *ngFor="let list of  MainFilterLocation.FilterLocation">
        <!-- <span (click)="filterLocation('mainlocation',list.MainLocationName)">{{list.MainLocationName}}</span> -->
        <input type="checkbox" [(ngModel)]="list.IsSelected" name="list.IsSelected">{{list.MainLocationName}}
      <!-- |{{list.IsSelected}} -->

        <div *ngFor="let sublist of  list.FilterSubLocation" class="sub-location-section">
          <input type="checkbox" [(ngModel)]="sublist.IsSelected" name="sublist.IsSelected">{{sublist.SubLocationName}}
         <!-- | {{sublist.IsSelected}} -->

        </div>
      </div>

    </div>
  </div>
    <div class="row">
      <div class="col-lg-4"></div>
      <div class="col-lg-4">
        <input type="submit" value="submit" class="btn btn-primary btn-block"/>
      </div>
      <div class="col-lg-4"></div>
    </div>
  </form>

</div>
</fieldset>
<!--<p [innerText]="workTask"></p>

 <div class="container" 
     cdkDropListGroup>
  <div class="example-container" 
       *ngFor="let list of projectLists">
    <h2>{{list.projectName}}</h2>

    <div cdkDropList 
          id="{{list.projectName}}" 
          [cdkDropListData]="list.products"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
      <div  *ngFor="let product of list.products"
            class="example-box" 
            cdkDrag
            [class.item__moved]="product.originalProjectName !== list.projectName">
        {{product.productName}}
      </div>
    </div>
  </div>
</div> -->
<!--Commented for testing on 27Jun24 -->
<!-- <fieldset class="section-legend-border col-md-12 col-lg-12" #DraggedReg id="DraggedRegion">
  <legend class="section-legend-border">Product Mapping</legend>
<div class="row" cdkDropListGroup >
  <div class="col-lg-2" *ngFor="let list of ItemCurrentList">
    <span>{{list.MainLocationName}}</span>

    <div cdkDropList id="{{list.MainLocationName}}" [attr.data-MainLocationIdStr]="list.MainLocationIdStr"
      [attr.data-LocationIdStr]="list.LocationIdStr" [cdkDropListData]="list.ProductItems" class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div *ngFor="let product of list.ProductItems" class="example-box" cdkDrag
        [class.item__moved]="product.MainLocationName !== list.MainLocationName">
        {{product.ProductName}}
      </div>
    </div>
  </div>
  <div class="col-lg-12">
    {{result}}
  </div>
</div>
</fieldset> -->
<fieldset class="section-legend-border col-md-12 col-lg-12" #DraggedReg id="DraggedRegion">
  <legend class="section-legend-border">Product Mapping</legend>
<div class="row" cdkDropListGroup >
  <div class="col-lg-4" *ngFor="let list of ItemCurrentList">
    <fieldset class="section-legend-border col-md-12 col-lg-12">
      <legend class="section-legend-border">{{list.MainLocationName}}</legend>
    <!-- <span>{{list.MainLocationName}}</span> -->

    <div cdkDropList id="{{list.MainLocationName}}" [attr.data-MainLocationIdStr]="list.MainLocationIdStr"
      [attr.data-LocationIdStr]="list.LocationIdStr" [cdkDropListData]="list.ProductItems" class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div *ngFor="let product of list.ProductItems" class="example-box" cdkDrag
        [class.item__moved]="product.MainLocationName !== list.MainLocationName">
        {{product.ProductName}}
      </div>
    </div>
    </fieldset>
  </div>
  <div class="col-lg-12">
    {{result}}
  </div>
</div>
</fieldset>