<fieldset class="section-legend-border col-md-12 col-lg-12" #Product >
  <legend class="section-legend-border">Product</legend>
<div class="row">
  <div class="col-sm-12">
    <form (ngSubmit)="onSubmit()" #productForm="ngForm">

      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Product Id</label>
          <input type="text" disabled [(ngModel)]="productModel.ProductID" name="productModel.ProductID"
            placeholder="ProductID" class="form-control">
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <ng-template #rt let-r="result" let-t="term">
            {{ r.ProductName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Product:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="productModel.ProductAutoComplete"
            [(ngModel)]="productModel.ProductAutoComplete" [ngbTypeahead]="search" [inputFormatter]="formatter"
            [resultTemplate]="rt" (selectItem)="selectedItemProduct($event)" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-8 col-lg-8">
          <label for="disabledTextInput">Product Name</label>
          <input type="text" [(ngModel)]="productModel.ProductName" name="productModel.ProductName"
            placeholder="Product Name" class="form-control" #ProductName="ngModel" (keydown)="changeProductName()">
          <div *ngIf="!ProductName.valid"> {{ProductName.errors.Required1}} {{ProductName.errors.Length}}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Vendor Id </label>
          <input disabled type="text" [(ngModel)]="productModel.VendorID" name="productModel.VendorID"
            placeholder="Vendor ID" class="form-control">
          <span
            *ngIf='productModel.VendorName != undefined && productModel.VendorName.length>0'>({{productModel.VendorName}})</span>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-6">
          <ng-template #rtVendor let-r="result" let-t="term">
            {{ r.VendorName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Vendor:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="productModel.VendorAutoComplete"
            [(ngModel)]="productModel.VendorAutoComplete" [ngbTypeahead]="searchVendor"
            [inputFormatter]="formatterVendor" [resultTemplate]="rtVendor" (selectItem)="selectedItemVendor($event)" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="disabledTextInput">Brand Id</label>
          <input type="text" disabled [(ngModel)]="productModel.BrandId" name="productModel.BrandId" placeholder="id"
            class="form-control">
          <span
            *ngIf='productModel.BrandName != undefined && productModel.BrandName.length>0'>({{productModel.BrandName}})</span>
        </div>
        <div class="form-group col-md-6">
          <ng-template #rtBrand let-r="result" let-t="term">
            {{ r.Name}}
          </ng-template>
          <label for="typeahead-basic">Search for a Brand:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="productModel.BrandAutoComplete"
            [(ngModel)]="productModel.BrandAutoComplete" [ngbTypeahead]="searchBrand" [inputFormatter]="formatterBrand"
            [resultTemplate]="rtBrand" (selectItem)="selectedItemBrand($event)" />
        </div>

      </div>

      <div class="form-row">
        <div class="form-group col-sm-12 col-md-2 col-lg-2">
          <label for="disabledTextInput">Amount</label>
          <input type="text" [(ngModel)]="productModel.Amount" name="productModel.Amount" placeholder="Amount"
            class="form-control">
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-2">
          <label for="disabledTextInput">ScratchPad</label>
          <input type="text" [(ngModel)]="ScratchPadAmount" name="ScratchPadAmount" placeholder="amount"
            class="form-control">
          <button type="button" (click)="scratchPadClick('plus')">+</button><button type="button"
            (click)="scratchPadClick('minus')">-</button>
          <button type="button" (click)="scratchPadClick('multiply')">*</button><button type="button"
            (click)="scratchPadClick('divide')">/</button>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3">
          <label for="disabledTextInput">Purchase Auto ID</label>
          <input type="text" [(ngModel)]="productModel.ProductAutoID" name="productModel.ProductAutoID"
            placeholder="Purchase Auto ID" class="form-control">
        </div>

        <div class="form-group col-sm-12 col-md-4 col-lg-3">
          <label for="disabledTextInput"> _ int last Ex. sonoff_</label>
          <input type="text" [(ngModel)]="productModel.ProductUniqueName" name="productModel.ProductUniqueName"
            placeholder="Product Unique Name" class="form-control" />
        </div>


      </div>
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #ForNotificationReminder >
        <legend class="section-legend-border">For Notification Reminder</legend>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4 col-lg-3">

          <label for="exampleInputPassword1">Purchase Date</label>
          <div class="input-group">

            <input class="form-control" placeholder="yyyy-mm-dd" name="productModel.PurchaseDateDisplay"
              [(ngModel)]="productModel.PurchaseDateDisplay" ngbDatepicker #d3="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d3.toggle()" type="button"></button>
            </div>
          </div>

        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3">

          <label for="exampleInputPassword1">Renew Date</label>
          <div class="input-group">

            <input class="form-control" placeholder="yyyy-mm-dd" name="productModel.RenewDateDisplay"
              [(ngModel)]="productModel.RenewDateDisplay" ngbDatepicker #d4="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"></button>
            </div>
          </div>

        </div>
        <div class="form-group col-sm-12 col-md-2 col-lg-2 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsRenewed"
            [(ngModel)]="productModel.IsRenewed">
          <label class="form-check-label" for="exampleCheck1">Is Renewed </label>
        </div>
      </div>
      </fieldset>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-2 col-lg-2 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsFood"
            [(ngModel)]="productModel.IsFood">
          <label class="form-check-label" for="exampleCheck1">Is Food </label>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsFD"
            [(ngModel)]="productModel.IsFD" (change)="fixedDepositeChanged()">
          <label class="form-check-label" for="exampleCheck1">Is FD </label>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsEntertainment"
            [(ngModel)]="productModel.IsEntertainment">
          <label class="form-check-label" for="exampleCheck1">Is Entertainment </label>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsForItemTracker"
            [(ngModel)]="productModel.IsForItemTracker">
          <label class="form-check-label" for="exampleCheck1">Is For ItemTracker </label>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsActive"
            [(ngModel)]="productModel.IsActive">
          <label class="form-check-label" for="exampleCheck1">Is Active </label>
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsForShoppingBasket"
            [(ngModel)]="productModel.IsForShoppingBasket">
          <label class="form-check-label" for="exampleCheck1">Is For Shopping Basket </label>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4 checkbox-margin">
          <input type="checkbox" class="form-check-input" id="exampleCheck1" name="productModel.IsForFoodLogging"
            [(ngModel)]="productModel.IsForFoodLogging">
          <label class="form-check-label" for="exampleCheck1">Is For Food Logging </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <ng-template #rtAccount let-r="result" let-t="term">
            {{ r.AccountName}}
          </ng-template>
          <label for="typeahead-basic">Search for a Account:</label>
          <input id="typeahead-basic" type="text" class="form-control" name="accountModel.AccountAutoComplete" 
          [(ngModel)]="accountModel.AccountAutoComplete" [ngbTypeahead]="searchAccount" [inputFormatter]="formatterAccount"
          [resultTemplate]="rtAccount" (selectItem)="selectedItemAccount($event)"
          />
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-3">
          <label for="disabledTextInput">Account Code</label>
          <input type="text" [(ngModel)]="productModel.AccountCode" name="productModel.AccountCode"
            placeholder="Account Code" class="form-control" disabled #AccountCodeValidation="ngModel">
          <span>{{productModel.AccountName}}</span>
          <!-- <div *ngIf="!AccountCodeValidation.valid"> {{AccountCodeValidation.errors?.Required}} </div> -->
        </div>
      </div>


      <div class="form-row" *ngIf="productModel.ProductUniqueName != undefined" id="prd-file-section">

        <div class="col-sm-12 col-md-6">
          <a href="{{baseFileRef}}{{ProductRelativePath}}{{productModel.ProductUniqueName}}.pdf" target="_blank">{{productModel.ProductUniqueName}}.pdf</a>
        </div>
        <div class="col-sm-12 col-md-6">
          <button type="button" class="btn btn-primary" ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="baseFileRef+ProductRelativePath+productModel.ProductUniqueName+'.pdf'">{{ isCopied == true ? '✓' : 'Copy' }}</button>
          <button type="button" class="btn btn-primary" ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathFold3+ProductRelativePath+productModel.ProductUniqueName+'.pdf'">{{ isCopied == true ? 'Fold 3 ✓' : 'Fold 3' }}</button>
          <button type="button" class="btn btn-primary" ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathAsus6Z+ProductRelativePath+productModel.ProductUniqueName+'.pdf'">{{ isCopied == true ? '6z ✓' : '6z' }}</button>
          <button type="button" class="btn btn-primary" ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathCommandCenter+ProductRelativePath+productModel.ProductUniqueName+'.pdf'">{{ isCopied == true ? 'PC ✓' : 'PC' }}</button>
          <button type="button" class="btn btn-primary" ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathLenovoLaptop+ProductRelativePath+productModel.ProductUniqueName+'.pdf'">{{ isCopied == true ? 'Laptop ✓' : 'Laptop' }}</button>
        </div>
      </div>

     
      <div class="form-row">

        <div class="form-group col-md-12">
          <label for="disabledTextInput">Summary Notes</label>
          <!-- <input type="text" [(ngModel)]="productModel.SummaryNotes" name="productModel.SummaryNotes" placeholder="Summary Notes" class="form-control"> -->
          <textarea type="text" [(ngModel)]="productModel.SummaryNotes" name="productModel.SummaryNotes"
            placeholder="Summary Notes" class="form-control" rows="5"></textarea>
        </div>
        <div class="form-group col-md-12">
          <p>Note: File logic works with unique name if needs to add any file</p>
          <ol>
            <li>Add a pdf file to scanned/Products Folder</li>
            <li>Save that file with product unique name with _ in the end for consistency </li>
            <li>Create a single file for invoice manual or any instruction</li>
            <li>Generate a single pdf only</li>
          </ol>
        </div>
      </div>
      <div id="fd-section" *ngIf="productModel.IsFD">
        <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #FixedDeposit >
          <legend class="section-legend-border">Fixed Deposit</legend>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-3 col-lg-3">
            <label for="disabledTextInput">Fixed Deposit</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.FixedDepositID"
              name="productModel.FixedDepositModel.FixedDepositID" placeholder="FDID" class="form-control">
          </div>
          <div class="form-group col-sm-12 col-md-3 col-lg-3">
            <label for="disabledTextInput">InterestRate</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.InterestRate"
              name="productModel.FixedDepositModel.InterestRate" placeholder="InterestRate" class="form-control">
          </div>

          <div class="form-group col-sm-12 col-md-4 col-lg-3">
            <label for="disabledTextInput">FD Deposit Amount</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.Amount"
              name="productModel.FixedDepositModel.Amount" placeholder="FixedDepositAmount" class="form-control" />
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-3">
            <label for="disabledTextInput">FD Maturity Amount</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.MaturityAmount"
              name="productModel.FixedDepositModel.MaturityAmount" placeholder="FixedDepositAmount"
              class="form-control" />
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-3">
            <label for="disabledTextInput">Interest Earned</label>
            <input type="text" value="{{productModel.FixedDepositModel.MaturityAmount-productModel.FixedDepositModel.Amount}}"
              name="productModel.FixedDepositModel.MaturityAmount" placeholder="FixedDepositAmount" disabled
              class="form-control" />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-3 col-lg-3">
            <label for="disabledTextInput">Bank FD ID</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.BankFDID"
              name="productModel.FixedDepositModel.BankFDID" placeholder="FDID" class="form-control">
          </div>


          <div class="form-group col-sm-12 col-md-4 col-lg-3">

            <label for="exampleInputPassword1">Deposite Date</label>
            <div class="input-group">

              <input class="form-control" placeholder="yyyy-mm-dd"
                name="productModel.FixedDepositModel.DepositeDateDisplay"
                [(ngModel)]="productModel.FixedDepositModel.DepositeDateDisplay" ngbDatepicker #d1="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
              </div>
            </div>

          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-3">

            <label for="exampleInputPassword1">Maturity Date</label>
            <div class="input-group">

              <input class="form-control" placeholder="yyyy-mm-dd"
                name="productModel.FixedDepositModel.MaturityDateDisplay"
                [(ngModel)]="productModel.FixedDepositModel.MaturityDateDisplay" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>

          </div>


        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="disabledTextInput">Fd Narration</label>
            <input type="text" [(ngModel)]="productModel.FixedDepositModel.Narration"
              name="productModel.FixedDepositModel.Narration" placeholder="Narration" class="form-control">
          </div>
        </div>
        </fieldset>
      </div>
      
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4">
          <button type="submit" class="btn btn-primary btn-block">Submit</button>
        </div>
        <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
      </div>
    </form>
  </div>
  <div class="col-sm-12">
    {{result}}
  </div>
</div>
</fieldset>
<fieldset class="section-legend-border col-md-12 col-lg-12" #Fd Filter >
  <legend class="section-legend-border">Fd Filter</legend>
<div class="row">
  <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
    <button type="button" (click)="showHideFD();toggle(1)" class="btn btn-primary btn-block"
      [ngClass]="{ 'activeButton' : isValue == 1 }"> {{isShowFd === true? 'Hide' : 'Show'}} FD</button>
  </div>
  <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
    <button type="button" (click)="filterFds('isnotmatured');toggle(2)" class="btn btn-primary btn-block"
      [ngClass]="{ 'activeButton' : isValue == 2 }">Is not Matured FD</button>
  </div>
  <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
    <button type="button" (click)="filterFds('clear');toggle(3)" class="btn btn-primary btn-block"
      [ngClass]="{ 'activeButton' : isValue == 3 }">Clear filter</button>
  </div>
</div>
<fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #SubFdFilter >
  <legend class="section-legend-border">Sub Fd Filter</legend>
<div class="row" *ngIf="isShowFd" id="fdFilter">
  <div class="form-group  col-md-3 col-sm-12 " style="margin-left: 29px;">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isMomOnly" [(ngModel)]="isMomOnly"
      (change)="filterFds('mom')">
    <label class="form-check-label" for="exampleCheck1">Mom</label>
    <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
  </div>
  <div class="form-group  col-md-3 col-sm-12 " style="margin-left: 29px;">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isPrashantOnly"
      [(ngModel)]="isPrashantOnly" (change)="filterFds('me')">
    <label class="form-check-label" for="exampleCheck1">Me</label>
    <!-- <label class="form-check-label"><input type="checkbox"> Remember me</label> -->
  </div>
  <div class="form-group  col-md-3 col-sm-12 " style="margin-left: 29px;">
    <input type="checkbox" class="form-check-input" id="exampleCheck1" name="isBankFilter" [(ngModel)]="isRetainFilter">
    <label class="form-check-label" for="exampleCheck1">Is Retain Filter</label>
  
  </div>
  <div class="form-group  col-md-3 col-sm-12 " >
    <label for="disabledTextInput">Fd Filter Name</label>
    <input type="text" [(ngModel)]="bankFilterName" name="bankFilterName" (ngModelChange)="searchByProductName()" class="form-control"
    placeholder="fd filter by name" />
  </div>

  <div class="form-group  col-md-3 col-sm-12 ">
    <button type="button" class="btn btn-primary btn-block" id="btnClearCheck" (click)="clearTotalCheckboxes('none')">Clear
      check</button>
  </div>
  <div class="form-group  col-md-3 col-sm-12">
    <button type="button" class="btn btn-primary btn-block" id="btnClearCheck" (click)="clearTotalCheckboxes('all')">All
      check</button>
  </div>
  
</div>
</fieldset>
</fieldset>
<!-- <pre>Model: {{ productModel.ProductAutoComplete | json }}</pre> -->
<div class="row productlist-section" id="productFdlist" *ngIf="isShowFd" [@inOutAnimation]>
  <fieldset class="section-legend-border col-md-12 col-lg-12" #FdList >
    <legend class="section-legend-border">Product With Fds List</legend>
  <div class="col-sm-12 example-section">
    <div class="">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">T</th>
            <th scope="col">Name</th>
           
            <th scope="col">Vendor</th>
            <th scope="col">MaturityAmount</th>
            <th scope="col">MaturityDate</th>
            <th scope="col">Amount</th>
            <th scope="col">InterestRate</th>
            <th scope="col">DepositeDate</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of productListWithFds;let i=index;"
            [ngClass]="item.isMatured == true ? 'maturity-section' : ''">
            <td><input type="checkbox" [(ngModel)]="item.IsCheckedForTotal" (ngModelChange)="checkedTotal()"
                name="item.IsCheckedForTotal{{i}}"></td>
            
            <th scope="row" (click)='getProductDetails(item.ProductAutoID)' class="view-click-hover">
              {{item.ProductName}}</th>
           
            <td>{{item.VendorName}}</td>
            <td>{{item.ExpenseFixedDepositModel.MaturityAmount}}</td>
            <td>{{item.ExpenseFixedDepositModel.MaturityDate| date: 'dd/MMM/yyyy'}}</td>
            <td>{{item.Amount|number:'1.2-2'}}</td>
            <td>{{item.ExpenseFixedDepositModel.InterestRate}}</td>
            <td>{{item.ExpenseFixedDepositModel.DepositeDate| date: 'dd/MMM/yyyy'}}</td>
        </tbody>
      </table>
    </div>
  </div>
  </fieldset>
</div>

<div class="row productlist-section" id="productlist">
  <fieldset class="section-legend-border col-md-12 col-lg-12" #ProductList >
    <legend class="section-legend-border">Product List</legend>
  <div class="col-sm-12 example-section">
    <div class="">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">AutoId</th>
            <th scope="col">Name</th>
            <th scope="col">Vendor</th>
            <th scope="col">Amount</th>
            <th scope="col">Is Food</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of productList">
            <td>{{item.ProductAutoID}}</td>
            <th scope="row" (click)='getProductDetails(item.ProductAutoID)' class="view-click-hover">
              {{item.ProductName}}</th>
            <td>{{item.VendorName}}</td>
            <td>{{item.Amount|customInr}}</td>
            <td>{{item.IsFood}}</td>
        </tbody>
      </table>
    </div>
  </div>
  </fieldset>
</div>
<span class="totalplaceholder" *ngIf="checkedSelectionTotal>0">{{checkedSelectionTotal|customInr}}</span>