import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ProductApiModel } from 'src/app/models/ExpenseManager/Product/product-api-model';
import { ShoppingBasketApiModel } from 'src/app/models/ExpenseManager/Product/shopping-basket-api-model';
import { ShoppingBasketMasterApiModel } from 'src/app/models/ExpenseManager/Product/shopping-basket-master-api-model';
import { ShoppingBasketModel } from 'src/app/models/ExpenseManager/Product/shopping-basket-model';
import { TransactionApiModel } from 'src/app/models/ExpenseManager/Transaction/transaction-api-model';
import { TransactionMasterModel } from 'src/app/models/ExpenseManager/Transaction/transaction-master-model';
import { VendorModel } from 'src/app/models/ExpenseManager/Vendor/vendor-model';
import { MeasurementModel } from 'src/app/models/FoodLogger/Measurement/measurement-model';
import { ExpenseManagerService } from 'src/app/services/expense-manager.service';
import { FoodLoggerService } from 'src/app/services/food-logger.service';

@Component({
  selector: 'app-shopping-basket',
  templateUrl: './shopping-basket.component.html',
  styleUrls: ['./shopping-basket.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class ShoppingBasketComponent implements OnInit {
  public productList: ProductApiModel[];
  public shoppingBasketList: ShoppingBasketModel[];
  public shoppingBasketListUntouched: ShoppingBasketModel[];
  public selectingShoppingBasketList: ShoppingBasketModel[];
  public selectingShoppingBasketListAPI: ShoppingBasketModel[];
  public UniqueVendors: VendorModel[];
  public UniqueVendorsPrint: VendorModel[];
  public selectedVendor: number;
  public selectedVendorPrint: number;
  public selectedVendorDbFilter: number;
  public shoppingBasketMasterApiModel: ShoppingBasketMasterApiModel;

  public savedShoppingBasketListFromDb: ShoppingBasketMasterApiModel[];
  public savedShoppingBasketListFromDbUnTouched: ShoppingBasketMasterApiModel[];
  public ShoppingBasketMasterID: number = 0;

  public IsDbFilterVisible = false;
  public isPrintViewVisible = false;
  public isRequiredError = 'Please select ';
  public resultReponse = '';
  public measurementModels: MeasurementModel[];
  public currentDateTime = '';
  public shoppingBasketMasterIdDbFilter=0;
  public projectedTotal=0;
  //Account section
  searching = false;
  searchFailed = false;
  public AccountAutoComplete:any;
  public accountCodeForCreditEntry;
  public accountNameForCreditEntry;
  public isUseUpdateDateDbFilter=false;
  result="";
  constructor(private expenseManagerService: ExpenseManagerService, private titleService: Title, private foodLoggerservice: FoodLoggerService) {
    this.titleService.setTitle("Shopping Basket");
    this.shoppingBasketList = []; this.shoppingBasketListUntouched = [];
    this.selectingShoppingBasketList = [];
    this.savedShoppingBasketListFromDb = [];
    this.shoppingBasketMasterApiModel = new ShoppingBasketMasterApiModel();
    this.shoppingBasketMasterApiModel.ShoppingBasketApiModels = [];
    this.UniqueVendors = [];
    this.UniqueVendorsPrint = [];
    this.currentDateTime = formatDate(Date.now(), 'yyyy-MM-dd_hh-mm', 'en-US', '+0530');
  }
  @ViewChild('shoppingbasketForm') shoppingbasketForm: HTMLFormElement;
  ngOnInit(): void {

    /// Step 1: Getting all shopping basket from db
    /// Step 2: Fetching all product looping them shopping db list
    ///         For udpating qty pricing etc.
    /// Step 3:
    this.expenseManagerService.GetShoppingBasketAllOrByID().subscribe(response => {

      this.savedShoppingBasketListFromDb = response.sort(function (a, b) {
        return new Date(b.Created).getTime() - new Date(a.Created).getTime();
      });;
      this.savedShoppingBasketListFromDbUnTouched = response.sort(function (a, b) {
        return new Date(b.Created).getTime() - new Date(a.Created).getTime();
      });;
      this.foodLoggerservice.GetAllMeasurement().subscribe(response => {
        this.measurementModels = response;
      });
     
      this.expenseManagerService.GetProductAllOrByID(0, true, false).subscribe(response => {
        this.productList = response;
        for (var item of this.productList) {
          var shoppingBasket = new ShoppingBasketModel();
          //setting qty pricing based on prevous list
          var found = 0;
          for (var itemDbMaster of this.savedShoppingBasketListFromDb) {

            for (var itemDb of itemDbMaster.ShoppingBasketApiModels) {
              // Setting print name
              if (itemDb.ProductName.indexOf('-') >= 0) {
                itemDb.ProductNamePrint = itemDb.ProductName.split('-')[1];
              }
              else {
                itemDb.ProductNamePrint = itemDb.ProductName;
              }

              // Setting quanity and quantity type
              if (itemDb.Quantity >= 1000) {
                itemDb.QuantityPrint = itemDb.Quantity / 1000;
                if (itemDb.MeasurementType.toLocaleLowerCase() === "gm") {
                  itemDb.MeasurementTypePrint = "Kg"
                }
                else if (itemDb.MeasurementType.toLocaleLowerCase() === "ml") {
                  itemDb.MeasurementTypePrint = "Ltr"
                }
                else {
                  itemDb.MeasurementTypePrint = itemDb.MeasurementType;
                }
              }
              else {
                itemDb.QuantityPrint = itemDb.Quantity;
                itemDb.MeasurementTypePrint = itemDb.MeasurementType;
              }

              if (itemDb.ProductID == item.ProductID) {
                shoppingBasket.Quantity = itemDb.Quantity;
                shoppingBasket.MeasurementType = itemDb.MeasurementType;
                shoppingBasket.MeasurementId = itemDb.MeasurementId;
                shoppingBasket.Amount = itemDb.Amount;
                found = 1;
                break;
              }
            }
            if (found > 0) { break; }
          }

          shoppingBasket.ProductID = item.ProductID
          shoppingBasket.ProductName = item.ProductName;
          // if(item.ProductName.indexOf('-')>=0){
          //   shoppingBasket.ProductName = item.ProductName.split('-')[1];
          // }
          // else{
          //   shoppingBasket.ProductName = item.ProductName;
          // }
          shoppingBasket.VendorID = item.VendorID;
          shoppingBasket.VendorName = item.VendorName;
          if(shoppingBasket.Amount === undefined){
            shoppingBasket.Amount = item.Amount;
          }
         
          shoppingBasket.AccountCode = item.AccountCode;

          shoppingBasket.IsCheckedForShopping = false;
          this.shoppingBasketList.push(shoppingBasket);
          this.shoppingBasketListUntouched.push(shoppingBasket);
        }

        //sorting by asc
        this.shoppingBasketList = this.shoppingBasketList.sort((t1, t2) => {
          const name1 = t1.ProductName.toLowerCase();
          const name2 = t2.ProductName.toLowerCase();
          if (name1 > name2) { return 1; }
          if (name1 < name2) { return -1; }
          return 0;
        });
        this.shoppingBasketListUntouched = this.shoppingBasketListUntouched.sort((t1, t2) => {
          const name1 = t1.ProductName.toLowerCase();
          const name2 = t2.ProductName.toLowerCase();
          if (name1 > name2) { return 1; }
          if (name1 < name2) { return -1; }
          return 0;
        });

        // this.shoppingBasketList[3].IsCheckedForShopping=true;

        var unqueVendorObject = [...new Map(this.productList.map(item =>
          [item['VendorID'], item])).values()];
        for (var item of unqueVendorObject) {
          var vendorModel = new VendorModel();
          vendorModel.VendorID = item.VendorID;
          vendorModel.VendorName = item.VendorName;
          this.UniqueVendors.push(vendorModel);
          this.UniqueVendorsPrint.push(vendorModel);
        }
      });
    });


  }
  getPageName(): string {
    console.log("works");
    return 'Shopping Basket';
  }

  addSelectedItem(item: ShoppingBasketModel) {
    if (item.IsCheckedForShopping) {
      item.MeasurementType = this.measurementModels.filter(items => items.MeasurementId == item.MeasurementId)[0].MeasurementType;;
      this.selectingShoppingBasketList.push(item);
      //get shoppingBasketMasterApiModel from basket model


      this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.push(this.convertProductModelToApiModel(item));

      // this.shoppingBasketMasterApiModel.ShoppingBasketApiModels = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.sort((t1, t2) => {
      //   const name1 = t1.ProductName.toLowerCase();
      //   const name2 = t2.ProductName.toLowerCase();
      //   if (name1 > name2) { return 1; }
      //   if (name1 < name2) { return -1; }
      //   return 0;
      // });
      //Calculating sum.
        this.projectedTotal = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.map(a => a.Amount).reduce(function(a, b)
        {
          return a + b;
        });
    }
    else {
      this.selectingShoppingBasketList = this.selectingShoppingBasketList.filter(items => items.ProductID !== item.ProductID);
      this.shoppingBasketMasterApiModel.ShoppingBasketApiModels = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.filter(items => items.ProductID !== item.ProductID);
    }

  }

  saveData() {
    // this.shoppingbasketForm
    var isValid = true;
    if (!this.selectedVendorPrint || this.selectedVendorPrint <= 0) {
      //this.foodLogModel.ServingQuantity =0;
      this.shoppingbasketForm.form.controls['selectedVendorPrint'].setErrors({ 'Required': this.isRequiredError + " vendor" });
      isValid = false;
    }
    if (!isValid) {
      return 0;
    }
    this.shoppingBasketMasterApiModel.VendorID = this.selectedVendorPrint;
    this.shoppingBasketMasterApiModel.VendorName = this.UniqueVendors.filter(items => items.VendorID == this.selectedVendorPrint)[0].VendorName;;
    var masterApiModel = new ShoppingBasketMasterApiModel();
    masterApiModel = this.shoppingBasketMasterApiModel;
    // masterApiModel.UserID = Number(localStorage.getItem('currentUser'));
    // masterApiModel.ShoppingBasketApiModels = [];
    // masterApiModel.VendorID = this.selectedVendor;
    // masterApiModel.VendorName = this.UniqueVendors.filter(items=>items.VendorID == this.selectedVendor)[0].VendorName;
    // masterApiModel.ShoppingBasketMasterID = this.ShoppingBasketMasterID;
    // for (var item of this.selectingShoppingBasketList) {
    //   var basketApiModel = new ShoppingBasketApiModel();
    //   basketApiModel.ProductID = item.ProductID
    //   basketApiModel.ProductName = item.ProductName.substr(0, 25);
    //   basketApiModel.MeasurementId = 1;
    //   basketApiModel.Amount = item.Amount;
    //   basketApiModel.Quantity = item.Qty;
    //   basketApiModel.MeasurementType = item.QtyType;
    //   basketApiModel.UserID = Number(localStorage.getItem('currentUser'));
    //   masterApiModel.ShoppingBasketApiModels.push(basketApiModel);
    //}

    this.expenseManagerService.InsertOrUpdateShoppingBasket(masterApiModel).subscribe(response => {
      var result = response;
      this.shoppingBasketMasterApiModel = response;
      this.ShoppingBasketMasterID = result.ShoppingBasketMasterID;
      this.resultReponse = response.ShoppingBasketMasterID + " - " + formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530');
    });

  }
  // For scratch shopping first time
  public onOptionsSelected(event) {

    this.shoppingBasketList = this.shoppingBasketListUntouched;

    const value = event.target.value;
    this.shoppingBasketMasterApiModel.VendorID = value;
    this.shoppingBasketMasterApiModel.VendorName = this.UniqueVendors.filter(items => items.VendorID == value)[0].VendorName;;
    this.selectedVendor = value;
    this.selectedVendorPrint = value;
    // this.shoppingBasketMasterApiModel.VendorID = value;
    // this.shoppingBasketMasterApiModel.VendorName = this.UniqueVendors.filter(items=>items.VendorID == this.selectedVendor)[0].VendorName;;
    //this.accountModel.AccountMasterName = event.target.options[event.target.options.selectedIndex].text;
    //this. = value;
    if (value > 0) {
      this.shoppingBasketList = this.shoppingBasketList.filter(
        function (e) {
          return e.VendorID == value;
        }
      );


    }


    //setting from db 
    //this.convertToPrintDisplayList(this.savedShoppingBasketList[0]);
    console.log(value);
  }
  public onOptionsSelectedPrint(event) {
    const value = event.target.value;
    this.shoppingBasketMasterApiModel.VendorID = value;
    this.shoppingBasketMasterApiModel.VendorName = this.UniqueVendors.filter(items => items.VendorID == value)[0].VendorName;;
  }

  public onOptionsSelectedDbFilter(event) {
    this.savedShoppingBasketListFromDb = this.savedShoppingBasketListFromDbUnTouched;
    const value = event.target.value;
    const shoppingIdMasterIdFilter = Number(this.shoppingBasketMasterIdDbFilter)
    if(shoppingIdMasterIdFilter >0){
      this.savedShoppingBasketListFromDb = this.savedShoppingBasketListFromDb.filter(
        function (e) {
          return e.ShoppingBasketMasterID == shoppingIdMasterIdFilter;
        }
      );
  
    }

    // Filter saved by vendor
    this.savedShoppingBasketListFromDb = this.savedShoppingBasketListFromDb.filter(
      function (e) {
        return e.VendorID == value;
      }
    );

    if(this.isUseUpdateDateDbFilter === true){
      this.savedShoppingBasketListFromDb = this.savedShoppingBasketListFromDb.sort(function (a, b) {
        return new Date(b.Updated).getTime() - new Date(a.Updated).getTime();
      });
    }
    else{
      this.savedShoppingBasketListFromDb = this.savedShoppingBasketListFromDb.sort(function (a, b) {
        return new Date(b.Created).getTime() - new Date(a.Created).getTime();
      });
    }
    

    // Selectin item list accordin to last saved values
    this.shoppingBasketMasterApiModel = this.savedShoppingBasketListFromDb[0];
    for(var baskeApitItem of this.shoppingBasketMasterApiModel.ShoppingBasketApiModels){
      for (var basketItem of this.shoppingBasketList){
        if(basketItem.ProductID == baskeApitItem.ProductID){
          basketItem.IsCheckedForShopping = true;
          basketItem.Quantity = baskeApitItem.Quantity;
        }

      }

    }
        //Setting vendor name
    this.selectedVendorPrint = value
    this.shoppingBasketMasterApiModel.VendorID = this.selectedVendorPrint;
    this.shoppingBasketMasterApiModel.VendorName = this.UniqueVendors.filter(items => items.VendorID == this.selectedVendorPrint)[0].VendorName;;

    this.shoppingBasketMasterApiModel.ShoppingBasketApiModels = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.sort((t1, t2) => {
      const name1 = t1.ProductName.toLowerCase();
      const name2 = t2.ProductName.toLowerCase();
      if (name1 > name2) { return 1; }
      if (name1 < name2) { return -1; }
      return 0;
    });
       //Calculating sum.
       this.projectedTotal=0;
       this.projectedTotal = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.map(a => a.Amount).reduce(function(a, b)
       {
         return a + b;
       });
  }
  // public convertToPrintDisplayList(savedShopppingBasketList: ShoppingBasketMasterApiModel) {
  //   this.selectingShoppingBasketList = [];
  //   var masterApiModel = new ShoppingBasketModel();
  //   //masterApiModel.UserID = Number(localStorage.getItem('currentUser'));
  //   //masterApiModel.ShoppingBasketApiModels = [];
  //   masterApiModel.VendorID = savedShopppingBasketList.VendorID;
  //   masterApiModel.VendorName = "Amazon shopping";
  //   for (var item of savedShopppingBasketList.ShoppingBasketApiModels) {
  //     var basketApiModel = new ShoppingBasketModel();
  //     basketApiModel.ProductID = item.ProductID;
  //     basketApiModel.ProductName = item.ProductName.substr(0, 25);
  //     basketApiModel.MeasurementType = item.MeasurementType;
  //     basketApiModel.Amount = item.Amount;
  //     basketApiModel.Quantity = item.Quantity;
  //     this.selectingShoppingBasketList.push(basketApiModel);
  //   }
  // }
  convertProductModelToApiModel(model: ShoppingBasketModel) {
    var basketApiModel = new ShoppingBasketApiModel();
    basketApiModel.ProductID = model.ProductID
    //this.email.Tos.split(",");
    if (model.ProductName.indexOf('-') >= 0) {
      basketApiModel.ProductNamePrint = model.ProductName.split('-')[1];
    }
    else {
      basketApiModel.ProductNamePrint = model.ProductName;
    }
    basketApiModel.ProductName = model.ProductName;
    //basketApiModel.ProductName = model.ProductName.substr(0, 25);
    basketApiModel.MeasurementId = model.MeasurementId;
    basketApiModel.Amount = model.Amount;
    basketApiModel.Quantity = model.Quantity;
    if (basketApiModel.Quantity >= 1000) {
      basketApiModel.QuantityPrint = basketApiModel.Quantity / 1000;
      if (model.MeasurementType.toLocaleLowerCase() === "gm") {
        basketApiModel.MeasurementTypePrint = "Kg"
      }
      else if (model.MeasurementType.toLocaleLowerCase() === "ml") {
        basketApiModel.MeasurementTypePrint = "Ltr"
      }
      else {
        basketApiModel.MeasurementTypePrint = model.MeasurementType;
      }
    }
    else {
      basketApiModel.QuantityPrint = basketApiModel.Quantity;
      basketApiModel.MeasurementTypePrint = model.MeasurementType;
    }
    basketApiModel.MeasurementType = model.MeasurementType;
    basketApiModel.AccountCode = model.AccountCode;
    basketApiModel.UserID = Number(localStorage.getItem('currentUser'));
    return basketApiModel;
  }
  exportAsPDF(divId) {
    let data = document.getElementById(divId);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  // 'image/jpeg' for lower quality output.
      //let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      let pdf = new jspdf('p', 'pt', 'a4'); //Generates PDF in portrait mode
      //pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);  
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 553, 84);
      //pdf.addImage(contentDataURL, 'PNG', 0, 0, 21.0, 29.7); 
      pdf.save('Filename.pdf');
    });
  }
  saveToTransactionTable() {
    if(this.accountCodeForCreditEntry === undefined){
      this.result ="Please select Account";
      return 0;
    }
   var UserID = Number(localStorage.getItem('currentUser'));
    var number = 1;
    var expenseApiModels = [];
    var transactionMasterModel = new TransactionMasterModel();
 
    for (var basketApiModel of this.shoppingBasketMasterApiModel.ShoppingBasketApiModels) {
      var apiModel = new TransactionApiModel();

      var amount = basketApiModel.Amount;
      //apiModel.TTransID = number++;
      apiModel.VoucherDateDisplay = basketApiModel.Created.toString();
      apiModel.VoucherType = "P";
      //apiModel.VoucherID = node.data.VoucherID;
      //voucherId = node.data.voucherID;
      apiModel.AccountCode = basketApiModel.AccountCode;
      apiModel.Amount = amount;
      apiModel.PayMode = "Q";//For Bank
      apiModel.TranType = "D";

      apiModel.Quantity = basketApiModel.Quantity;
      apiModel.Measurement = 1;// quantity x measurement
      apiModel.MeasurementId = basketApiModel.MeasurementId;
      apiModel.ProductID = Number.isNaN(Number(basketApiModel.ProductID)) ? 0 : Number(basketApiModel.ProductID);

      apiModel.Narration = "Entry from ShoppingBasket"
      expenseApiModels.push(apiModel);
    }

   // Crdit entry
   var basketApiModel =this.shoppingBasketMasterApiModel.ShoppingBasketApiModels[0];
   var apiModel = new TransactionApiModel();

   var amount = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.map(a => a.Amount).reduce(function(a, b)
   {
     return a + b;
   });;
   //apiModel.TTransID = number++;
   apiModel.VoucherDateDisplay = basketApiModel.Created.toString();
   apiModel.VoucherType = "P";
   //apiModel.VoucherID = node.data.VoucherID;
   //voucherId = node.data.voucherID;
   apiModel.AccountCode =  this.accountCodeForCreditEntry ;
   apiModel.Amount = amount;
   apiModel.PayMode = "Q";//For Bank
   apiModel.TranType = "C";

   apiModel.Quantity = basketApiModel.Quantity;
   apiModel.Measurement = 1;// quantity x measurement
   apiModel.MeasurementId = basketApiModel.MeasurementId;
   apiModel.ProductID = Number.isNaN(Number(basketApiModel.ProductID)) ? 0 : Number(basketApiModel.ProductID);

   apiModel.Narration = "Entry from ShoppingBasket"
   expenseApiModels.push(apiModel);
    transactionMasterModel.ExpenseTTransModel = expenseApiModels;
    transactionMasterModel.VoucherDateDisplay =this.shoppingBasketMasterApiModel.Created.toString();
    transactionMasterModel.VendorID = this.shoppingBasketMasterApiModel.VendorID;
    transactionMasterModel.VendorName = this.shoppingBasketMasterApiModel.VendorName;
    transactionMasterModel.SummaryNotes = "Entry from ShoppingBasket";
    this.expenseManagerService.saveTransaction(transactionMasterModel).subscribe(response => {
      console.log(response);
      this.result = response.VoucherID + ' : ' + formatDate(Date.now(), 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530') + " | Voucher Dt: " + formatDate(transactionMasterModel.VoucherDateDisplay, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530')
      });
  }

  	    /*Account section */
        searchAccount = (text$: Observable<string>) =>
        text$.pipe(
          debounceTime(300),
          distinctUntilChanged(),
          tap(() => this.searching = true),
          switchMap(term => term.length < 2 ? of([]) :
            (this.expenseManagerService.GetAccountByName(term).pipe(
              tap(() => this.searchFailed = false),
              catchError(() => {
                this.searchFailed = true;
                return of([]);
              })))
          ),
          tap(() => this.searching = false)
        )
      formatterAccount = (x: { AccountName: string }) => x.AccountName;
      
      selectedItemAccount(item) {
        //this.clickedItem=item.item;
        this.accountCodeForCreditEntry = item.item.AccountCode;
        this.accountNameForCreditEntry = item.item.AccountName;
        
      }
}
