import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { TestTableService } from './services/test-table.service';
import { TestTableComponent } from './test-table/test-table.component';
import { LoginComponent } from './login/login.component';
import{AuthGuardService as AuthGuard} from './guards/auth-guard.service';
import { RegisterComponent } from './register/register.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HighChartSampleComponent } from './high-chart-sample/high-chart-sample.component';
import { AMasterSensorComponent } from './CustomModule/temperature/amaster-sensor/amaster-sensor.component';
import { RoomSensorComponent } from './CustomModule/temperature/room-sensor/room-sensor.component';
import { AExpenseMasterComponent } from './CustomModule/expense-manager/aexpense-master/aexpense-master.component';
import { VendorComponent } from './CustomModule/expense-manager/vendor/vendor.component';
import { TransactionComponent } from './CustomModule/expense-manager/transaction/transaction.component';
import { ProductComponent } from './CustomModule/expense-manager/product/product.component';
import { AccountComponent } from './CustomModule/expense-manager/account/account.component';
import { ProductDetailsComponent } from './CustomModule/expense-manager/product-details/product-details.component';
import { HabitTrackerMasterComponent } from './CustomModule/habit-tracker/habit-tracker-master/habit-tracker-master.component';
import { HabitCategoryComponent } from './CustomModule/habit-tracker/habit-category/habit-category.component';
import { HabitTypeComponent } from './CustomModule/habit-tracker/habit-type/habit-type.component';
import { HabitComponent } from './CustomModule/habit-tracker/habit/habit.component';
import { HabitMappingComponent } from './CustomModule/habit-tracker/habit-mapping/habit-mapping.component';
import { LoghabitComponent } from './CustomModule/habit-tracker/loghabit/loghabit.component';
import { BrandComponent } from './CustomModule/food-logger/brand/brand.component';
import { AFoodLoggerMasterComponent } from './CustomModule/food-logger/afood-logger-master/afood-logger-master.component';
import { MealTypeComponent } from './CustomModule/food-logger/meal-type/meal-type.component';
import { FoodComponent } from './CustomModule/food-logger/food/food.component';
import { FoodLogComponent } from './CustomModule/food-logger/food-log/food-log.component';
import { FoodImageComponent } from './CustomModule/food-logger/food-image/food-image.component';
import { SensorWidgetComponent } from './widgets/sensor-widget/sensor-widget.component';
import { ASensorDataMasterComponent } from './CustomModule/sensor-data/a-sensor-data-master/a-sensor-data-master.component';
import { SensorComponent } from './CustomModule/sensor-data/sensor/sensor.component';
import { SensorFeatureTypeComponent } from './CustomModule/sensor-data/sensor-feature-type/sensor-feature-type.component';
import { SensorLocationComponent } from './CustomModule/sensor-data/sensor-location/sensor-location.component';
import { SensorSubLocationComponent } from './CustomModule/sensor-data/sensor-sub-location/sensor-sub-location.component';
import { SensorMappingComponent } from './CustomModule/sensor-data/sensor-mapping/sensor-mapping.component';
import { AInterviewMasterComponent } from './CustomModule/interview/ainterview-master/ainterview-master.component';
import { QuestionAnswerComponent } from './CustomModule/interview/question-answer/question-answer.component';
import { TopicComponent } from './CustomModule/interview/topic/topic.component';
import { SubjectComponent } from './CustomModule/interview/subject/subject.component';
import { VitalInformationMeasurementComponent } from './CustomModule/vital-information/vital-information-measurement/vital-information-measurement.component';
import { ItemTrackerMasterComponent } from './CustomModule/item-tracker/item-tracker-master/item-tracker-master.component';
import { ItemTrackerLocationModel } from './models/ItemTracker/item-tracker-location-model';
import { ItemLocationComponent } from './CustomModule/item-tracker/item-location/item-location.component';
import { ItemCurrentLocationComponent } from './CustomModule/item-tracker/item-current-location/item-current-location.component';
import { ItemStockMapperComponent } from './CustomModule/item-tracker/item-stock-mapper/item-stock-mapper.component';
import { QrcodeExComponent } from './qrcode-ex/qrcode-ex.component';
import { AFitBitMasterComponent } from './CustomModule/fit-bit/afit-bit-master/afit-bit-master.component';
import { FitBitSleepTrackerComponent } from './CustomModule/fit-bit/fit-bit-sleep-tracker/fit-bit-sleep-tracker.component';
import { FitBitActivityComponent } from './CustomModule/fit-bit/fit-bit-activity/fit-bit-activity.component';
import { FitBitBodyComponent } from './CustomModule/fit-bit/fit-bit-body/fit-bit-body.component';
import { FitBitWaterComponent } from './CustomModule/fit-bit/fit-bit-water/fit-bit-water.component';
import { QuestionBankComponent } from './CustomModule/interview/question-bank/question-bank.component';
import { ListItemsByCurrentLocationComponent } from './CustomModule/item-tracker/list-items-by-current-location/list-items-by-current-location.component';
import { GuestGuardGuard } from './guards/guest-guard.guard';
import { ItemCurrentLocationDragDropComponent } from './CustomModule/item-tracker/item-current-location-drag-drop/item-current-location-drag-drop.component';
import { WaterTankComponent } from './CustomModule/sensor-data/water-tank/water-tank.component';
import { FitBitUpdateTokenComponent } from './CustomModule/fit-bit/fit-bit-update-token/fit-bit-update-token.component';
import { OpeningBalanceComponent } from './CustomModule/expense-manager/opening-balance/opening-balance.component';
import { FileComponent } from './CustomModule/expense-manager/file/file.component';
import { HabitReportComponent } from './CustomModule/habit-tracker/habit-report/habit-report.component';
import { CanvasTracingComponent } from './canvas-tracing/canvas-tracing.component';
import { FoodByNutrutionComponent } from './CustomModule/food-logger/food-by-nutrution/food-by-nutrution.component';
import { AMasterSmartBagComponent } from './CustomModule/smart-bag/amaster-smart-bag/amaster-smart-bag.component';
import { MasterComboComponent } from './CustomModule/smart-bag/master-combo/master-combo.component';
import { ShoppingBasketComponent } from './CustomModule/expense-manager/shopping-basket/shopping-basket.component';
import { BulkFoodLoggerComponent } from './CustomModule/food-logger/bulk-food-logger/bulk-food-logger.component';
import { JarvisDashboardComponent } from './jarvis-dashboard/jarvis-dashboard.component';

const routes: Routes = [
  {
    path:'',
    component:HomeComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'home',
    component:HomeComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'dashboard',
    component:DashBoardComponent
  },
  {
    path:'jarvisbbashboard',
    component:JarvisDashboardComponent
  },
  {
    path:'testtable',
    component:TestTableComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'login',
    component:LoginComponent,
  },
  {
    path:'login/:loginaction',
    component:LoginComponent,
  },
  {
    path:'login/:loginaction/:returnurl',
    component:LoginComponent,
  },
  {
    path:'register',
    component:RegisterComponent
  },
  {
    path:'forgetpassword',
    component:ForgetpasswordComponent
  },
  {
    path:'resetpassword',
    component:ResetPasswordComponent
  },
  {
    path:'highchart',
    component:HighChartSampleComponent
  },
  {
    path:'widgetsensor',
    component:SensorWidgetComponent
  },
  {
    path:'sensor',component:AMasterSensorComponent, 
    //canActivate:[AuthGuard],
    children:[
      { path: 'roomsensor', component: RoomSensorComponent},
      { path: 'watertank', component: WaterTankComponent},
      
    ]
  },
  {
    path:'qrex',
    component:QrcodeExComponent
  },
  {
    path:'tracing',
    component:CanvasTracingComponent
  },
  {
    path:'expense',component:AExpenseMasterComponent, 
    canActivate:[AuthGuard],
    children:[
      { path: 'managevendor', component: VendorComponent},
      {path:'product',component:ProductComponent},
      {path:'account',component:AccountComponent},
       {path:'transaction',component:TransactionComponent},
      {path:'productaccountdetails',component:ProductDetailsComponent},
      {path:'openingbalance',component:OpeningBalanceComponent},
      {path:'file',component:FileComponent},
      {path:'shopping',component:ShoppingBasketComponent}
    ]
  },
  {
    path:'habit',component:HabitTrackerMasterComponent,
    canActivate:[AuthGuard],
    children:[
      {path:'category',component:HabitCategoryComponent},
      {path:'type',component:HabitTypeComponent},
      {path:'habit',component:HabitComponent},
      {path:'mapping',component:HabitMappingComponent},
      { path: 'log', component: LoghabitComponent},
      { path: 'report', component: HabitReportComponent},
    ]
  },
  {
    path:'foodlogger',component:AFoodLoggerMasterComponent,
    canActivate:[AuthGuard],
    children:[
      {path:'managebrand',component:BrandComponent},
       {path:'managemealtype',component:MealTypeComponent},
       {path:'createfood',component:FoodComponent},
       {path:'logfood',component:FoodLogComponent},
       { path: 'addfoodimage', component: FoodImageComponent},
       { path: 'foodbynutrition', component: FoodByNutrutionComponent},
       { path: 'bulkFoodLogger', component: BulkFoodLoggerComponent},
    ]
  },
  {
    path:'sensordata',component:ASensorDataMasterComponent,
    canActivate:[AuthGuard],
    children:[
      {path:'managesensor',component:SensorComponent},
       {path:'managefeatureType',component:SensorFeatureTypeComponent},
       {path:'manageLocation',component:SensorLocationComponent},
       {path:'managesublocation',component:SensorSubLocationComponent},
       { path: 'managesensormapping', component: SensorMappingComponent},
    ]
  },
  {
    path:'interview',component:AInterviewMasterComponent ,
    canActivate:[AuthGuard],
    children:[
      {path:'managequestionanswer',component:QuestionAnswerComponent},
      {path:'managetopic',component:TopicComponent},
      {path:'managesubject',component:SubjectComponent},
      { path: 'questionbank', component: QuestionBankComponent},
    ]
  },
  {
    path:'vitalinfo',component:AInterviewMasterComponent ,
    canActivate:[AuthGuard],
    children:[
      {path:'measurement',component:VitalInformationMeasurementComponent},
      // {path:'managetopic',component:TopicComponent},
      // {path:'managesubject',component:SubjectComponent},
      //  { path: 'managesensormapping', component: SensorMappingComponent},
    ]
  },
  {
    path:'itemtracker',component:ItemTrackerMasterComponent ,
    //canActivate:[AuthGuard],
    children:[
      {path:'managelocation',component:ItemLocationComponent,canActivate:[AuthGuard]},
      {path:'managecurrentlocation',component:ItemCurrentLocationComponent,canActivate:[AuthGuard]},
      {path:'stockmapping',component:ItemStockMapperComponent,canActivate:[AuthGuard]},
      {path:'itemlistbylocation',component:ListItemsByCurrentLocationComponent,canActivate:[GuestGuardGuard]},
      {path:'managecurrentlocationdrg',component:ItemCurrentLocationDragDropComponent,canActivate:[GuestGuardGuard]},
      //  { path: 'managesensormapping', component: SensorMappingComponent},
    ]
  },
  {
    path:'fitbit',component:AFitBitMasterComponent ,
    //canActivate:[AuthGuard],
    children:[
      {path:'sleep',component:FitBitSleepTrackerComponent},
       {path:'activity',component:FitBitActivityComponent},
       {path:'body',component:FitBitBodyComponent},
       { path: 'water', component: FitBitWaterComponent},
       { path: 'fitbittoken', component: FitBitUpdateTokenComponent},
    ]
  }, 
  {
    path:'smartbag',component:AMasterSmartBagComponent ,
    //canActivate:[AuthGuard],
    children:[
      {path:'mastercombo',component:MasterComboComponent},
      
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
