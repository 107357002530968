import { Component, OnInit } from '@angular/core';
import { FoodLogModel } from 'src/app/models/FoodLogger/FoodLog/food-log-model';
import { MealTypeModel } from 'src/app/models/FoodLogger/MealType/meal-type-model';
import { FoodLoggerService } from 'src/app/services/food-logger.service';

@Component({
  selector: 'app-bulk-food-logger',
  templateUrl: './bulk-food-logger.component.html',
  styleUrls: ['./bulk-food-logger.component.css']
})
export class BulkFoodLoggerComponent implements OnInit {
  public foodlogModelList:FoodLogModel[];
  public selectedFoodlogModelList:FoodLogModel[];
   public mealTypeModelList:MealTypeModel[];
    result='';
constructor(private foodLogService:FoodLoggerService){
this.foodlogModelList=[];
this.mealTypeModelList=[];
this.selectedFoodlogModelList=[];
}
ngOnInit(): void {
  this.foodLogService.GetUniqueFoodItemFromFoodLogs().subscribe(response=>{
    this.foodlogModelList = response;
    this.foodLogService.GetAllMealType().subscribe(response => {
      this.mealTypeModelList = response;
       //sorting by asc
       this.foodlogModelList = this.foodlogModelList.sort((t1, t2) => {
        const name1 = t1.ProductName.toLowerCase();
        const name2 = t2.ProductName.toLowerCase();
        if (name1 > name2) { return 1; }
        if (name1 < name2) { return -1; }
        return 0;
      });


    },
      err => { this.result = err.message; }

    );
  });
}
addSelectedItem(item:FoodLogModel){
  if (item.IsCheckedForFoodLog) {
    //item.MeasurementType = this.measurementModels.filter(items => items.MeasurementId == item.MeasurementId)[0].MeasurementType;;
    this.selectedFoodlogModelList.push(item);
    //get shoppingBasketMasterApiModel from basket model


   // this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.push(this.convertProductModelToApiModel(item));
  }
  else {
    this.selectedFoodlogModelList = this.selectedFoodlogModelList.filter(items => items.FoodId !== item.FoodId);
    //this.shoppingBasketMasterApiModel.ShoppingBasketApiModels = this.shoppingBasketMasterApiModel.ShoppingBasketApiModels.filter(items => items.ProductID !== item.ProductID);
  }

}
}
