import { ShoppingBasketApiModel } from "./shopping-basket-api-model";

export class ShoppingBasketMasterApiModel {

    public  ShoppingBasketMasterID:number;
    public  VendorID:number;
    public  VendorName:string;
    public  SummaryNotes:string;
    public  UserID:number;
    public IsAvailableToPublic:boolean ;
    public  Created:Date ;
    public  Updated:Date ;
    public  ShoppingBasketApiModels:ShoppingBasketApiModel[];
}
