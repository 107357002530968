<div class="row">
  <div class="col-sm-12">
    <div id="form-section">
      <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Voucher>
          <legend class="section-legend-border">Voucher Current Fy Year {{currentFyYear}}</legend>
          <div class="form-row">
            <div class="form-group col-md-8">
              <ng-template #rtVoucher let-r="result" let-t="term">
                {{ r.TransactionDetail}}
              </ng-template>
              <label for="typeahead-basic">Search for a VoucherID:</label>
              <input id="typeahead-basic" type="text" class="form-control" name="transactionModel.VoucherIDAutoComplete"
                [(ngModel)]="transactionModel.VoucherIDAutoComplete" [ngbTypeahead]="searchVoucher"
                [inputFormatter]="formatterVoucher" [resultTemplate]="rtVoucher"
                (selectItem)="selectedItemVoucher($event)" />
            </div>
          </div>
          <div class="form-row"> 
            <div class="form-group col-sm-12 col-md-4 col-lg-3 " id="clearRedisCache">
              <!-- <button class="btn btn-lg btn-outline-primary" (click)="openVoucherDetailsPopup(12323)">Launch demo modal</button> -->
              <div class="input-group" > <input type="button" (click)="clearRedisCache()" value="Clear Redis Cache" class="btn btn-primary btn-block"/> </div>
            </div>
            <div class="form-group col-sm-12 col-md-4 col-lg-3 " id="setPreviousYear">
              <div class="input-group" > <input type="button" (click)="setPreviousYear()" value="Set Previous Year" class="btn btn-primary btn-block"/> </div>
            </div>
            <div class="form-group col-sm-12 col-md-2 col-lg-2 " id="setFinancialYear">
              <div class="input-group" >  <input type="number" [(ngModel)]="currentFyYear" name="currentFyYear" class="form-control ng-untouched ng-pristine ng-valid" /></div> 
            </div>
            <div class="form-group col-sm-12 col-md-2 col-lg-2 " id="setFinancialYear">
               <div class="input-group" > <input type="button" (click)="setFinancialYear()" value="Set FY" class="btn btn-primary btn-block"/> </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4 col-lg-3" >
              <label for="exampleInputPassword1">Manual Voucher Date</label>
              <div class="input-group"><input type="text" mask="0000-M0-d0" [dropSpecialCharacters]="false" class="form-control" [(ngModel)]="transactionModel.VoucherDateDisplayMask" 
                name="transactionModel.VoucherDateDisplayMask" (change)="maskToOriginalDate()" /></div>
            </div>
            <div class="form-group col-sm-12 col-md-4 col-lg-3 " id="updateButton">
              <label for=""></label>
              <div class="input-group" > <input type="button" (click)="updateGridDataDate()" value="Update Date" class="btn btn-primary btn-block"/> </div>
            </div>
         
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4 col-lg-3 ">
              <label for="exampleInputPassword1">Voucher Date</label>
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="transactionModel.VoucherDateDisplay" 
                  [(ngModel)]="transactionModel.VoucherDateDisplay" ngbDatepicker #d="ngbDatepicker"
                  (ngModelChange)="onModelChange($event)" >
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
            </div>

            <div class="form-group col-sm-12 col-md-4 col-lg-2">
              <div id="time-picker-section">
                <ngb-timepicker name="transactionModel.VoucherTimeDisplay"
                  [(ngModel)]="transactionModel.VoucherTimeDisplay">
                </ngb-timepicker>
              </div>
              <!-- <label for="exampleInputPassword1">Voucher Time</label> -->

            </div>

          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Brand>
          <legend class="section-legend-border">Brand</legend>
          <div class="form-row">
            <div class="form-group col-md-6">
              <ng-template #rtVendor let-r="result" let-t="term">
                {{ r.VendorName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Vendor:</label>
              <input id="typeahead-basic" type="text" class="form-control"
                name="transactionMasterModel.VendorAutoComplete" [(ngModel)]="transactionMasterModel.VendorAutoComplete"
                [ngbTypeahead]="searchVendor" [inputFormatter]="formatterVendor" [resultTemplate]="rtVendor"
                (selectItem)="selectedItemVendor($event)" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4 col-lg-2">
              <label for="disabledTextInput">Vendor Id</label>
              <input type="text" [(ngModel)]="transactionMasterModel.VendorID" name="transactionMasterModel.VendorID"
                placeholder="id" class="form-control" disabled #VendorValidation="ngModel">
            </div>
            <div class="form-group col-sm-12 col-md-4 col-lg-4">
              <label for="disabledTextInput">Vendor Name</label>
              <input type="text" [(ngModel)]="transactionMasterModel.VendorName"
                name="transactionMasterModel.VendorName" placeholder="Vendor Name" class="form-control" disabled>
              <div *ngIf="!VendorValidation.valid"> {{VendorValidation.errors?.Required}} </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Transaction id="Transaction-Section">
          <legend class="section-legend-border">Transaction</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4 col-lg-2">
              <label for="disabledTextInput">Transaction Id</label>
              <input type="text" [(ngModel)]="transactionModel.TTransID" name="transactionModel.TTransID"
                placeholder="id" class="form-control" disabled>
            </div>
            <div class="form-group col-sm-12 col-md-4 col-lg-2">
              <label for="voucherType">Voucher Type</label>
              <select class="form-control" name="transactionModel.VoucherType"
                [(ngModel)]="transactionModel.VoucherType">
                <option *ngFor="let item of transactionModel.VoucherTypeList" [value]="item.Value">
                  {{item.Name}}
                </option>
              </select>
            </div>
            <div class="form-group col-md-2" >
              <label for="voucherType">Tran Type</label>
              <select class="form-control" name="transactionModel.TranType" [(ngModel)]="transactionModel.TranType">
                <option *ngFor="let item of transactionModel.TranTypeList" [value]="item.Value">
                  {{item.Name}}
                </option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <label for="voucherType">Pay Mode</label>
              <select class="form-control" name="transactionModel.PayMode" [(ngModel)]="transactionModel.PayMode">
                <option *ngFor="let item of transactionModel.PaymentTypeList" [value]="item.Value">
                  {{item.Name}}
                </option>
              </select>
            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Product id="Product-Section">
          <legend class="section-legend-border">Product</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-4 col-lg-4">
              <ng-template #rt let-r="result" let-t="term">
                {{ r.ProductName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Product:</label>
              <input id="typeahead-basic" type="text" class="form-control" name="transactionModel.ProductIDAutoComplete"
                [(ngModel)]="transactionModel.ProductIDAutoComplete" [ngbTypeahead]="search"
                [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="selectedItemProduct($event)" />
            </div>
            <div class="form-group col-md-2">
              <label for="disabledTextInput">Product ID</label>
              <input type="text" [(ngModel)]="transactionModel.ProductID" name="transactionModel.ProductID"
                placeholder="Product ID" class="form-control" disabled>
              <span>{{transactionModel.ProductName}}</span>
            </div>

          </div>
          <div class="form-row">

            <div class="form-group col-md-2">
              <label for="disabledTextInput">Amount</label>
              <input type="text" [(ngModel)]="transactionModel.Amount" name="transactionModel.Amount"
                placeholder="amount" class="form-control">
            </div>
            <div class="form-group col-sm-12 col-md-4 col-lg-2">
              <label for="disabledTextInput">ScratchPad</label>
              <input type="text" [(ngModel)]="ScratchPadAmount" name="ScratchPadAmount" placeholder="amount"
                class="form-control">
              <button type="button" (click)="scratchPadClick('plus')">+</button><button type="button"
                (click)="scratchPadClick('minus')">-</button>
              <button type="button" (click)="scratchPadClick('multiply')">*</button><button type="button"
                (click)="scratchPadClick('divide')">/</button>
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-2">
              <label for="disabledTextInput">Quantity</label>
              <input type="text" [(ngModel)]="transactionModel.Quantity" name="transactionModel.Quantity"
                placeholder="Quantity" class="form-control">
              <span>{{transactionModel.Quantity}}</span>
            </div>
            <div class="form-group col-md-2">
              <label for="disabledTextInput">Measr.</label>
              <input type="text" [(ngModel)]="transactionModel.Measurement" name="transactionModel.Measurement"
                placeholder="Measurement" class="form-control">
              <span>{{transactionModel.Measurement}}</span>
            </div>
            <div class="form-group col-md-3">
              <label for="exampleFormControlSelect1">Measr. Type</label>
              <select class="form-control" id="MeasurementId" name="transactionModel.MeasurementId"
                [(ngModel)]="transactionModel.MeasurementId">
                <option [ngValue]="undefined" disabled selected> Select Measr. </option>
                <option *ngFor="let item of transactionModel.MeasurementModel" [value]="item.MeasurementId">
                  {{item.MeasurementType}}
                </option>
              </select>
            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #RecentAccCode>
          <legend class="section-legend-border">Recent Account Code</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-12 recent-account-section">
              <span *ngFor="let item of recentAccounts"
                (click)="selectedRecentAccounts(item.AccountCode,item.AccountName)">
                {{item.AccountName}} | {{item.AccountCode}}
              </span>
            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Account>
          <legend class="section-legend-border">Account</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-5 col-lg-4">
              <ng-template #rtAccount let-r="result" let-t="term">
                ({{r.AccountCode}}) {{ r.AccountName}}
              </ng-template>
              <label for="typeahead-basic">Search for a Account Code:</label>
              <input id="typeahead-basic" type="text" class="form-control"
                name="transactionModel.AccountCodeAutoComplete" [(ngModel)]="transactionModel.AccountCodeAutoComplete"
                [ngbTypeahead]="searchAccount" [inputFormatter]="formatterAccount" [resultTemplate]="rtAccount"
                (selectItem)="selectedItemAccount($event)" />

            </div>

            <div class="form-group col-sm-12 col-md-4 col-lg-3">
              <label for="disabledTextInput">Account Code</label>
              <input type="text" [(ngModel)]="transactionModel.AccountCode" name="transactionModel.AccountCode"
                placeholder="Account Code" class="form-control" disabled #AccountCodeValidation="ngModel">
              <span>{{transactionModel.AccountName}}</span>
              <div *ngIf="!AccountCodeValidation.valid"> {{AccountCodeValidation.errors?.Required}} </div>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-12">
              <label for="disabledTextInput">Narration</label>
              <input type="text" [(ngModel)]="transactionModel.Narration" name="transactionModel.Narration"
                placeholder="Narration" class="form-control">
            </div>
          </div>
        </fieldset>
        <fieldset class="section-legend-border  col-md-10 col-lg-10" #Final>
          <legend class="section-legend-border">Final</legend>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-3 col-lg-3">
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6">
              <button type="submit" class="btn btn-primary btn-block">Add</button>
            </div>
            <div class="form-group col-sm-12 col-md-3 col-lg-3">
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-sm-12 col-md-12 col-lg-12">
              <label for="disabledTextInput">Final Summary</label>
              <input type="text" [(ngModel)]="transactionMasterModel.SummaryNotes"
                name="transactionMasterModel.SummaryNotes" placeholder="Summary Notes" class="form-control">
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-sm-12 col-md-3 col-lg-3">
            </div>
            <div class="form-group col-sm-12 col-md-6 col-lg-6" #DiffDrCr>
              <button type="button" (click)="scratchPadClick('addDifferenceCrDr')" class="btn btn-primary btn-block">Add
                Difference Cr Dr</button>
            </div>
            <div class="form-group col-sm-12 col-md-3 col-lg-3">
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>

</div>
<div class="row">
  <div class="col-sm-12">
    <div id="add-button-section">
      <div id="btn-remove-section">
        <button (click)="onRemoveSelected()">-</button>
      </div>

    </div>
  </div>
</div>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #TransactionGrid>
  <legend class="section-legend-border">Transaction Grid</legend>
  <div class="row">
    <div class="col-sm-12">
      <div id="transaction-grid-section">

        <div id="transaction-grid">
          <ag-grid-angular #agGrid style="width: 100%; height: 500px;" class="ag-theme-balham" [rowData]="rowData"
            [columnDefs]="columnDefs" [pagination]="true" paginationPageSize=30 [context]="context"
            [getRowNodeId]="getRowNodeId" (rowSelected)='onRowSelected($event)'
            (selectionChanged)="onSelectionChanged($event)" [rowSelection]="rowSelection"
            [pinnedBottomRowData]="pinnedBottomRowData">
          </ag-grid-angular>
        </div>

      </div>
    </div>
  </div>
</fieldset>
<div class="form-row">
  <div class="form-group col-md-4"></div>
  <div class="form-group col-md-4 col-sm-12">
    <button (click)="onSaveData()" class="btn btn-primary btn-block">Save</button>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    {{result}}
    <!-- <button ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="'example string'">{{ isCopied == true ? 'Copied' : 'Copy' }}</button> -->
  </div>
</div>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #Filters>
  <legend class="section-legend-border">Filters</legend>
  <div class="row">
    <div class="form-group col-md-2 checkbox-margin">
      <input type="checkbox" class="form-check-input" id="isRetainFilterCheck" name="isRetainFilter"
        [(ngModel)]="isRetainFilter">
      <label class="form-check-label" for="isRetainFilterCheck">Is Retain Filter </label>
    </div>
    <div class="form-group col-md-2 checkbox-margin">
      <input type="checkbox" class="form-check-input" id="isDebitCheck" name="isDebit" [(ngModel)]="isDebit"
        (change)="changeDebit()">
      <label class="form-check-label" for="isDebitCheck">Show Debit </label>
    </div>
    <div class="form-group col-md-2 checkbox-margin">
      <input type="checkbox" class="form-check-input" id="isShowRelatedFiles" name="isShowRelatedFiles"
        [(ngModel)]="isShowRelatedFiles" (change)="changeDebit()">
      <label class="form-check-label" for="isShowRelatedFiles">Show Related Files </label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
      <button type="button" (click)="showHideOpBalAccount();toggle(1)" class="btn btn-primary btn-block"
        [ngClass]="{ 'activeButton' : isValue == 1 }"> {{isShowOpBalAccount === true? 'Hide' : 'Show'}} Op Bal
        A/c</button>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
      <button type="button" (click)="showHidenoOpBalAccount();toggle(2)" class="btn btn-primary btn-block"
        [ngClass]="{ 'activeButton' : isValue == 2 }">{{isShowNoOpBalAccount === true? 'Hide' : 'Show'}} Non Op Bal
        A/c</button>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
      <button type="button" (click)="showHideMonthFilter();toggle(3)" class="btn btn-primary btn-block"
        [ngClass]="{ 'activeButton' : isValue == 3 }">{{isShowMonthFilter === true? 'Hide' : 'Show'}} Month
        Filter</button>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
      <button type="button" (click)="showHideEntriesEx();toggle(4)" class="btn btn-primary btn-block"
        [ngClass]="{ 'activeButton' : isValue == 4 }">{{isShowEntriesEx === true? 'Hide' : 'Show'}} Entries Ex.</button>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-4 margin-bottom-10">
      <button type="button" (click)="setFormData()" class="btn btn-primary btn-block">Reset form data</button>
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2 margin-bottom-10">
      <input type="text" [(ngModel)]="recentEntriesVendorFilter" name="recentEntriesVendorFilter"
      placeholder="Vendor name" class="form-control" class="form-control">
    </div>
    <div class="col-sm-12 col-md-2 col-lg-2 margin-bottom-10">
      <button type="button" (click)="filterTransaction(recentEntriesVendorFilter,'vendor')" class="btn btn-primary btn-block">Go</button>
    </div>
  </div>
  <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #AccCodeWithOpBlance *ngIf="isShowOpBalAccount">
    <legend class="section-legend-border">Op Bal Ac's</legend>
    <div class="row" id="op-balance" *ngIf="isShowOpBalAccount">
      <span *ngFor="let item of accOpenCodeFilterAlphbets" (click)="scroll(item)" class="scrollFilter">
        {{item}}
      </span>
<!-- 
      <span *ngFor="let item of openingBalAmountList" (click)="filterTransaction(item.AccountCode,'acccode')">
        {{item.Year}} | {{item.AccountCode}} - {{item.BalAmount|customInr}}
      </span> -->
     
        <ng-container *ngFor="let alphaChar of accOpenCodeFilterAlphbets">
          <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" 
          *ngIf="isShowOpBalAccount"  id="{{alphaChar}}">
            <legend class="section-legend-border">{{alphaChar}}</legend>
            <div class="col-sm-12">
              <ng-container *ngFor="let item of openingBalAmountList">
                <span  (click)="filterTransaction(item.AccountCode,'acccode')" *ngIf="item.AccountCode.charAt(0).toLowerCase() === alphaChar">
                  {{item.Year}} | {{item.AccountCode}} - {{item.BalAmount|customInr}}
                </span>
              </ng-container>
            </div>
         </fieldset>
   
      </ng-container>

    </div>
  </fieldset>
  <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #AccCodeWithOpBlance *ngIf="isShowNoOpBalAccount">
    <legend class="section-legend-border">Non Op Bal Ac's</legend>
  <div class="row" id="no-op-balance" *ngIf="isShowNoOpBalAccount">
    <span *ngFor="let item of accNonOpenCodeFilterAlphbets" (click)="scroll(item)" class="scrollFilter">
      {{item}}
    </span>
    <!-- <span *ngFor="let item of noOpeningBalAmountList" (click)="filterTransaction(item.AccountCode,'acccode')">
      {{item.Year}} | {{item.AccountCode}} - {{item.BalAmount|customInr}}
    </span> -->
    <ng-container *ngFor="let alphaChar of accNonOpenCodeFilterAlphbets">
      <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #{{alphaChar}}
      *ngIf="isShowNoOpBalAccount"  id="{{alphaChar}}">
        <legend class="section-legend-border">{{alphaChar}}</legend>
        <div class="col-sm-12">
          <ng-container *ngFor="let item of noOpeningBalAmountList">
            <span  (click)="filterTransaction(item.AccountCode,'acccode')" *ngIf="item.AccountCode.charAt(0).toLowerCase() === alphaChar">
              {{item.Year}} | {{item.AccountCode}} - {{item.BalAmount|customInr}}
            </span>
          </ng-container>
        </div>
     </fieldset>
  
  </ng-container>
  </div>
  </fieldset>
</fieldset>

<div class="row" id="showRelatedFiles" *ngIf="isShowRelatedFiles">
  <h6>Related files (Use tasker <b>Open File</b> task on mobile after copy the path)</h6>
  <div *ngFor="let item of fileModel.Filelist;let i = index" class="related-files-urls col-sm-12">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <a href="{{baseFileRef}}{{item.RelativeFilePath}}" target="_blank">{{i+1}}. {{item.FileName}}</a>
      </div>
      <div class="col-sm-12 col-md-6">
        <button ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="baseFileRef+item.RelativeFilePath">{{ isCopied
          == true ? '✓' : 'Copy' }}</button>
        <button ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathFold3+item.RelativeFilePath">{{
          isCopied == true ? 'Fold 3 ✓' : 'Fold 3' }}</button>
        <button ngxClipboard (cbOnSuccess)="isCopied = true" [cbContent]="FileBasePathAsus6Z+item.RelativeFilePath">{{
          isCopied == true ? '6z ✓' : '6z' }}</button>
        <button ngxClipboard (cbOnSuccess)="isCopied = true"
          [cbContent]="FileBasePathCommandCenter+item.RelativeFilePath">{{ isCopied == true ? 'PC ✓' : 'PC' }}</button>
        <button ngxClipboard (cbOnSuccess)="isCopied = true"
          [cbContent]="FileBasePathLenovoLaptop+item.RelativeFilePath">{{ isCopied == true ? 'Laptop ✓' : 'Laptop'
          }}</button>
      </div>
    </div>


    <!-- {{baseFileRef}}{{item.RelativeFilePath}} -->
  </div>

</div>
<div class="row" id="month-filter-section" *ngIf="isShowMonthFilter">

  <span *ngFor="let item of months;let i = index" (click)="filterTransaction(item.monthNo,'month')"
    [ngClass]="{'selectedOption':item.selected}">
    {{item.monthName}}
  </span>
</div>

<div class="row" id="tran-example" *ngIf="isShowEntriesEx">
  <div class="col-sm-12 example-section" (click)="executeTemplate('purchase')">
    <div class="">
      <p class="section-heading-text">Purchased Prime membership with HDFC account 999</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">VType</th>
            <th scope="col">AcCode</th>
            <th scope="col">TType</th>
            <th scope="col">PID</th>
            <th scope="col">Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">P</th>
            <td>PA1 (Purchase account)</td>
            <td>D</td>
            <td>1(Prime mship)</td>
            <td>999</td>
          </tr>
          <tr>
            <th scope="row">P</th>
            <td>HDFC</td>
            <td>C</td>
            <td>1(Prime mship)</td>
            <td>999</td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>

  <div class="col-sm-12 example-section" (click)="executeTemplate('purchaseworthy')">
    <div class="">
      <p class="section-heading-text">Purchased HDD (3500) using Prime membership with HDFC with 100rs discount because
        of Prime</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">VType</th>
            <th scope="col">AcCode</th>
            <th scope="col">TType</th>
            <th scope="col">PID</th>
            <th scope="col">Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">P</th>
            <td>PA1 (Prchse account)</td>
            <td>D</td>
            <td>2 (Hdd)</td>
            <td>3500</td>
          </tr>
          <tr>
            <th scope="row">P</th>
            <td>HDFC</td>
            <td>C</td>
            <td>2 (Hdd)</td>
            <td>3400</td>
          </tr>
          <tr>
            <th scope="row">P</th>
            <td>PW1 (Prchse Worthy)</td>
            <td>C</td>
            <td>1 (Prime mship)</td>
            <td>100</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="col-sm-12 example-section" (click)="executeTemplate('rentworthy')">
    <div class="">
      <p class="section-heading-text">Hdd is used and its rent for using 100</p>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">VType</th>
            <th scope="col">AcCode</th>
            <th scope="col">TType</th>
            <th scope="col">PID</th>
            <th scope="col">Amt</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">R</th>
            <td>EXP1 (Expense)</td>
            <td>D</td>
            <td>2 (Hdd)</td>
            <td>100</td>
          </tr>
          <tr>
            <th scope="row">R</th>
            <td>PW1 (P worthy)</td>
            <td>C</td>
            <td>2 (Hdd)</td>
            <td>100</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

</div>
<fieldset class="section-legend-border  col-md-12 col-lg-12" #RecentEntries>
  <legend class="section-legend-border">Recent Entries</legend>
  <div class="row" id="recent-tran-example">
    <div class="col-sm-12 example-section">
      <div class="">
        <!-- <p class="section-heading-text">Product List</p> -->
        <table class="table">
          <thead>
            <tr>
              <th scope="col">VoucherID</th>
              <th scope="col">VoucherDate</th>
              <th scope="col">AccountCode</th>
              <th scope="col">AccountName</th>
              <th scope="col">Amount</th>
              <th scope="col">Narration</th>
              <th scope="col">Products</th>
              <th scope="col">Vender Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of recentTransactions">
              <th scope="row" (click)='getVoucherDetailsByVoucherID(item.VoucherID)' class="voucherid-hover">
                {{item.VoucherID}} <span (click)="openVoucherDetailsPopup(item.VoucherID)"><i class="fa fa-info-circle" aria-hidden="true"></i></span></th>
              <td>{{item.VoucherDate | date: 'dd/MMM/yyyy'}}</td>
              <td>{{item.AccountCode}}</td>
              <td>{{item.AccountName}}</td>
              <td>{{item.Amount|customInr}}</td>
              <td>{{item.Narration}}</td>
              <td>{{item.ProductName}}</td>
              <td>{{item.VendorName}}</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</fieldset>
<div class="col-sm-12 scroll-link scroll-section">
  <div (click)="scrollTohash(Transaction)">DrCr</div>
  <div (click)="scrollTohash(Product)">Prd</div>
   <div (click)="scrollTohash(Account)">Acs</div>
   <div (click)="scrollTohash(Final)">Add</div>
   <div (click)="scrollTohash(TransactionGrid)">Grd</div>  
   <div (click)="scrollTohash(Final)">DifCD</div>
   <div (click)="scrollTohash(RecentAccCode)">RecAc</div>
   
  <!--<div (click)="scroll(F)">Acs</div> -->
</div>
