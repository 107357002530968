<fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #StockMapping >
  <legend class="section-legend-border">Stock Mapping</legend>
<p>Mapping only works with UniqueName with _ at last Ex. sonoff_ and Need to entry on transaction log with prev yr is also fine.</p>
<fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #QrCodeAdjust >
  <legend class="section-legend-border">Qrcode Adjuster</legend>

<p> Qr Code Width  <input type="text" [(ngModel)]="qrwidth" name="qrwidth" placeholder="Comment" class="form-control" class="col-lg-4"></p>
</fieldset>
<div class="row" id="Item-StockMapping-List">
  <div class="col-sm-12 example-section">
    <div class="">
      <p class="section-heading-text">brandList List</p>
      <form (ngSubmit)="onSubmit()" #loginForm="ngForm">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">SerialNo</th>
              <th scope="col">ProductSearchName</th>
              <th scope="col">FinalProductUniqueName</th>
              <th scope="col">ProductId</th>
              <th scope="col">ProductName</th>
              <th scope="col">VoucherID</th>
              <th scope="col">TTranID</th>
              <th scope="col">Quantity</th>
              <th scope="col">MappingID</th>
              <th scope="col">QR Code</th>
              <th scope="col">OutOfStock</th>
            
              <th scope="col">comment</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of GetAllItemsForStockModelList;let i=index;">
              <th scope="row">{{item.SerialNo}}</th>
              <td>
                <input type="text" [(ngModel)]="item.ProductSearchName" name="item.ProductSearchName{{i}}" placeholder="Search name" class="form-control">
            </td>
              <td>{{item.FinalProductUniqueName}}</td>
              <td>{{item.ProductId}}</td>
              <td>{{item.ProductName}}</td>
              <td>{{item.VoucherID}}</td>
              <td>{{item.TTranID}}</td>
              <td>{{item.Quantity}}</td>
              <td>{{item.ID}}</td>
              <td>
                <qr-code [value]="item.FinalProductUniqueName" [size]="qrwidth"></qr-code>
              </td>
              <td><input type="checkbox" class="form-check-input" id="item.IsOutDated{{i}}" name="item.IsOutDatedN{{i}}"
                  [(ngModel)]="item.IsOutDated">
                <label class="form-check-label" for="exampleCheck1">Is OutDated </label>
              </td>
            
              <td>
                <input type="text" [(ngModel)]="item.Comment" name="item.Comment{{i}}" placeholder="Comment" class="form-control">
            </td>
          </tbody>
        </table>
        <div class="form-row">
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4">
            <button type="submit" class="btn btn-primary btn-block">Submit</button>
          </div>
          <div class="form-group col-sm-12 col-md-4 col-lg-4"></div>
        </div>
   
      </form>
    </div>
      <div class="col-sm-12">
        {{result}}
      </div>
  </div>
</div>
</fieldset>