<p>bulk-food-logger works!</p>
<div class="row">
    <fieldset class="section-legend-border sub-section-legend col-md-12 col-lg-12" #Product>
        <legend class="section-legend-border">Add Food List For Logging</legend>
        <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-12" id="shoppingList">
                    <table class="">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <!-- <th scope="col">S.No.</th> -->
                                <th scope="col" class="product-th">Name</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Qty Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Amt chnge</th>
                                <th scope="col">AccCode</th>
                                <th scope="col">Vendor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of selectedFoodlogModelList;let i=index;">
                                <td>
                                    <input type="checkbox" [(ngModel)]="item.IsCheckedForFoodLog"
                                        (ngModelChange)="addSelectedItem(item)"
                                        name="item.IsCheckedForFoodLog{{i}}" class="largerCheckbox">
                                </td>
                                <!-- <td>{{i + 1}}.</td> -->
                                <td scope="row" class="product-td"> {{item.ProductName}}</td>
                                <td class="quantity-td">
                                    <span (click)="item.Quantity=item.Quantity-250;item.Quantity=item.Quantity<=0?250:item.Quantity"
                                    class="custom-link">
                                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                </span>
                                <input type="number" [(ngModel)]="item.Quantity" name="item.Quantity{{i}}"
                                    class="td-quantity-input">
                                <span (click)="item.Quantity=item.Quantity+250" class="custom-link"><i
                                        _ngcontent-bkv-c47="" class="fas fa-plus-circle"></i>
                                </span>
                            </td>
                            <td class="quantity-td">
                                <!-- <label for="exampleFormControlSelect1">Measurement Type</label> -->
                                <select class="form-control" id="MealTypeId"
                                    name="item.MealTypeId" class="measure-td-select"
                                    [(ngModel)]="item.MealTypeId">
                                    <option [ngValue]="undefined" disabled selected> Meal Type </option>
                                    <option *ngFor="let mealTypeModel of mealTypeModelList" [value]="mealTypeModel.MealTypeId">
                                        {{mealTypeModel.Name}}
                                    </option>
                                </select>
                                {{item.Name}}
    
                            </td>
                                <td> {{item.CalculatedPrice|customInr}} </td>
                           
                            </tr>
    
                        </tbody>
                    </table>
                    <div class="">
                    </div>
                </div>
            </div>
        </div>
    <div class="col-sm-12">
     
        <div class="row">
            <div class="col-sm-12" id="shoppingList">
                <table class="">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <!-- <th scope="col">S.No.</th> -->
                            <th scope="col" class="product-th">Name</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Qty Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Amt chnge</th>
                            <th scope="col">AccCode</th>
                            <th scope="col">Vendor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of foodlogModelList;let i=index;">
                            <td>
                                <input type="checkbox" [(ngModel)]="item.IsCheckedForFoodLog"
                                    (ngModelChange)="addSelectedItem(item)"
                                    name="item.IsCheckedForFoodLog{{i}}" class="largerCheckbox">
                            </td>
                            <!-- <td>{{i + 1}}.</td> -->
                            <td scope="row" class="product-td"> {{item.ProductName}}</td>
                            <td class="quantity-td">
                                <span (click)="item.Quantity=item.Quantity-250;item.Quantity=item.Quantity<=0?250:item.Quantity"
                                class="custom-link">
                                <i class="fa fa-minus-circle" aria-hidden="true"></i>
                            </span>
                            <input type="number" [(ngModel)]="item.Quantity" name="item.Quantity{{i}}"
                                class="td-quantity-input">
                            <span (click)="item.Quantity=item.Quantity+250" class="custom-link"><i
                                    _ngcontent-bkv-c47="" class="fas fa-plus-circle"></i>
                            </span>
                        </td>
                        <td class="quantity-td">
                            <!-- <label for="exampleFormControlSelect1">Measurement Type</label> -->
                            <select class="form-control" id="MealTypeId"
                                name="item.MealTypeId" class="measure-td-select"
                                [(ngModel)]="item.MealTypeId">
                                <option [ngValue]="undefined" disabled selected> Meal Type </option>
                                <option *ngFor="let mealTypeModel of mealTypeModelList" [value]="mealTypeModel.MealTypeId">
                                    {{mealTypeModel.Name}}
                                </option>
                            </select>
                            {{item.Name}}

                        </td>
                            <td> {{item.CalculatedPrice|customInr}} </td>
                       
                        </tr>

                    </tbody>
                </table>
                <div class="">
                </div>
            </div>
        </div>
    </div>
    </fieldset>
</div>